/**
 * This module defines assigns authenticated route ownership to our squads.
 *
 * See https://launchdarkly.atlassian.net/wiki/spaces/ENG/pages/419497807/Squads for
 * the official list.
 */

import { AppRouteOwnership } from '@gonfalon/router';

export const appRouteOwnership: AppRouteOwnership = {
  '/': 'release-fe',
  '/*': 'release-fe',
  '/confirm-auth/:deviceCode': 'release-fe',
  '/insights': 'release-fe',
  '/product-analytics/integrations/snowflake': 'release-fe',
  '/internal/new-hires': 'release-fe',
  '/profile': 'release-fe',
  '/projects/:projectKey': 'release-fe',
  '/projects/:projectKey/ai-configs': 'release-fe',
  '/projects/:projectKey/ai-configs/:configKey': 'release-fe',
  '/projects/:projectKey/ai-configs/:configKey/monitoring': 'release-fe',
  '/projects/:projectKey/ai-configs/:configKey/playground': 'release-fe',
  '/projects/:projectKey/ai-configs/:configKey/targeting': 'release-fe',
  '/projects/:projectKey/ai-configs/:configKey/targeting/pending-changes': 'release-fe',
  '/projects/:projectKey/ai-configs/:configKey/variations': 'release-fe',
  '/projects/:projectKey/ai-configs/:configKey/variations/:variationKey': 'release-fe',
  '/projects/:projectKey/ai-configs/new': 'release-fe',
  '/projects/:projectKey/approvals': 'fm-foundations-eng',
  '/projects/:projectKey/approvals/:approvalRequestId': 'fm-foundations-eng',
  '/projects/:projectKey/audit': 'release-fe',
  '/projects/:projectKey/audit/:auditId/*': 'release-fe',
  '/projects/:projectKey/compare': 'fm-foundations-eng',
  '/projects/:projectKey/compare/:environmentPair': 'fm-foundations-eng',
  '/projects/:projectKey/compare/:environmentPair/flags/:flagKey': 'fm-foundations-eng',
  '/projects/:projectKey/contexts': 'decision-science',
  '/projects/:projectKey/contexts/instances/:instanceId': 'decision-science',
  '/projects/:projectKey/contexts/kinds': 'decision-science',
  '/projects/:projectKey/contexts/kinds/:kind/instances/:key': 'decision-science',
  '/projects/:projectKey/errors': 'release-observability',
  '/projects/:projectKey/errors/:issueId': 'release-observability',
  '/projects/:projectKey/errors/:issueId/json': 'release-observability',
  '/projects/:projectKey/experiments': 'decision-science',
  '/projects/:projectKey/experiments/new': 'decision-science',
  '/projects/:projectKey/experiments/:experimentId/details': 'decision-science',
  '/projects/:projectKey/experiments/:experimentKey': 'decision-science',
  '/projects/:projectKey/experiments/:experimentKey/design': 'decision-science',
  '/projects/:projectKey/experiments/:experimentKey/events': 'decision-science',
  '/projects/:projectKey/experiments/:experimentKey/iterations': 'decision-science',
  '/projects/:projectKey/experiments/:experimentKey/iterations/:iterationId': 'decision-science',
  '/projects/:projectKey/experiments/:experimentKey/results': 'decision-science',
  '/projects/:projectKey/experiments/:experimentKey/traffic': 'decision-science',
  '/projects/:projectKey/experiments/create': 'decision-science',
  '/projects/:projectKey/flags': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/add-to-code': 'release-fe',
  '/projects/:projectKey/flags/:flagKey/approvals/:approvalRequestId': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/code-refs': 'release-fe',
  '/projects/:projectKey/flags/:flagKey/environment-overview': 'fm-foundations-eng',
  //settings for data export, rollout salts & triggers
  '/projects/:projectKey/flags/:flagKey/environments': 'fm-foundations-eng',
  //rule exclusion settings
  '/projects/:projectKey/flags/:flagKey/environments/exclude-rules': 'decision-science',
  '/projects/:projectKey/flags/:flagKey/experiments/*': 'decision-science',
  '/projects/:projectKey/flags/:flagKey/history': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/history/:auditId/*': 'fm-foundations-eng',
  //redirects to monitoring tab
  '/projects/:projectKey/flags/:flagKey/insights': 'release-observability',
  //redirects to flag targeting tab
  '/projects/:projectKey/flags/:flagKey/links': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/monitoring': 'release-observability',
  '/projects/:projectKey/flags/:flagKey/settings': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/settings/archive': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/settings/deprecate': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/targeting': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/targeting/conflicts/:conflictIndex': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/targeting/exclude-rules': 'decision-science',
  '/projects/:projectKey/flags/:flagKey/targeting/pending-changes': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/targeting/pending-changes/:pendingId': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/variations': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/variations/conflicts/:conflictIndex': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/variations/pending-changes': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/variations/pending-changes/:pendingId': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/workflows': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/workflows/:workflowId': 'fm-foundations-eng',
  '/projects/:projectKey/flags/:flagKey/workflows/new': 'fm-foundations-eng',
  '/projects/:projectKey/flags/new': 'fm-foundations-eng',
  //redirects to /projects/:projectKey/flags/new
  '/projects/:projectKey/flags/connect': 'fm-foundations-eng',
  '/projects/:projectKey/get-started': 'release-fe',
  '/projects/:projectKey/get-started/invite-members': 'release-fe',
  '/projects/:projectKey/get-started/quickstart': 'release-fe',
  '/projects/:projectKey/get-started/setup-cli': 'release-fe',
  '/projects/:projectKey/guarded-rollouts': 'release-observability',
  '/projects/:projectKey/holdouts': 'decision-science',
  '/projects/:projectKey/holdouts/:holdoutKey': 'decision-science',
  '/projects/:projectKey/holdouts/:holdoutKey/details': 'decision-science',
  '/projects/:projectKey/holdouts/create': 'decision-science',
  '/projects/:projectKey/install-sdk': 'release-fe',
  '/projects/:projectKey/live': 'release-fe',
  '/projects/:projectKey/live/flags': 'release-fe',
  '/projects/:projectKey/live/flags/:flagKey': 'release-fe',
  '/projects/:projectKey/metrics': 'decision-science',
  '/projects/:projectKey/metrics/:metricKey/details': 'decision-science',
  '/projects/:projectKey/metrics/:metricKey/impact': 'decision-science',
  '/projects/:projectKey/metrics/analytics': 'decision-science',
  '/projects/:projectKey/metrics/analytics/new/:analyticsQueryResultId': 'decision-science',
  '/projects/:projectKey/metrics/discover': 'decision-science',
  '/projects/:projectKey/metrics/groups/:metricGroupKey/details': 'decision-science',
  '/projects/:projectKey/metrics/new': 'decision-science',
  '/projects/:projectKey/observe': 'decision-science',
  '/projects/:projectKey/observe/new-metric': 'decision-science',
  '/projects/:projectKey/observe/expand/:eventKind/:eventKey': 'decision-science',
  '/projects/:projectKey/onboarding': 'release-fe',
  '/projects/:projectKey/onboarding/:onboardingPath': 'release-fe',
  '/projects/:projectKey/releases/:releasePipelineKey': 'release-observability',
  '/projects/:projectKey/segments': 'fm-foundations-eng',
  '/projects/:projectKey/segments/:segmentKey': 'fm-foundations-eng',
  '/projects/:projectKey/segments/:segmentKey/flags': 'fm-foundations-eng',
  '/projects/:projectKey/segments/:segmentKey/history/:auditId/*': 'fm-foundations-eng',
  '/projects/:projectKey/segments/:segmentKey/history/*': 'fm-foundations-eng',
  '/projects/:projectKey/segments/:segmentKey/overview': 'fm-foundations-eng',
  '/projects/:projectKey/segments/:segmentKey/settings': 'fm-foundations-eng',
  '/projects/:projectKey/segments/:segmentKey/targeting': 'fm-foundations-eng',
  '/projects/:projectKey/segments/:segmentKey/targeting/exports/:exportKey': 'fm-foundations-eng',
  '/projects/:projectKey/segments/new': 'fm-foundations-eng',
  '/projects/:projectKey/sessions': 'release-observability',
  '/projects/:projectKey/sessions/:sessionId/*': 'release-observability',
  '/projects/:projectKey/settings': 'release-fe',
  '/projects/:projectKey/settings/context-kinds': 'decision-science',
  '/projects/:projectKey/settings/model-configs': 'release-fe',
  '/projects/:projectKey/settings/model-configs/:configKey': 'release-fe',
  '/projects/:projectKey/settings/environments': 'release-fe',
  '/projects/:projectKey/settings/environments/:envKey/approval-settings': 'release-fe',
  '/projects/:projectKey/settings/environments/:envKey/edit': 'release-fe',
  '/projects/:projectKey/settings/environments/:envKey/keys': 'release-fe',
  '/projects/:projectKey/settings/environments/new': 'release-fe',
  '/projects/:projectKey/settings/flags': 'release-fe',
  '/projects/:projectKey/settings/payload-filters': 'release-fe',
  '/projects/:projectKey/settings/payload-filters/:filterKey/edit': 'release-fe',
  '/projects/:projectKey/settings/release-pipelines': 'release-observability',
  '/projects/:projectKey/settings/release-pipelines/:releasePipelineKey': 'release-observability',
  '/projects/:projectKey/settings/release-pipelines/new': 'release-observability',
  '/settings': 'release-fe',
  '/settings/applications': 'release-fe',
  '/settings/applications/:applicationKey': 'release-fe',
  '/settings/applications/:applicationKey/edit': 'release-fe',
  '/settings/applications/:applicationKey/history/*': 'release-fe',
  '/settings/applications/:applicationKey/versions': 'release-fe',
  '/settings/applications/:applicationKey/versions/:versionKey/support-status': 'release-fe',
  '/settings/applications/:applicationKey/versions/new': 'release-fe',
  '/settings/applications/:applicationKey/source-maps': 'decision-science',
  '/settings/applications/new': 'release-fe',
  '/settings/authorization': 'release-fe',
  '/settings/authorization/oauth/:appId/review': 'release-fe',
  '/settings/authorization/roles/:key': 'release-fe',
  '/settings/authorization/tokens/:tokenId/clone': 'release-fe',
  '/settings/authorization/tokens/:tokenId/edit': 'release-fe',
  '/settings/authorization/tokens/:tokenId/reset': 'release-fe',
  '/settings/authorization/tokens/new': 'release-fe',
  '/settings/billing': 'release-fe',
  '/settings/billing/plans': 'release-fe',
  '/settings/billing/plans/review': 'release-fe',
  '/settings/escalate': 'release-fe',
  '/settings/general': 'release-fe',
  '/settings/history/*': 'release-fe',
  '/settings/integrations': 'release-fe',
  '/settings/integrations/:kind/:subscriptionId/edit': 'release-fe',
  '/settings/integrations/:kind/:subscriptionId/errors/view': 'release-fe',
  '/settings/integrations/:kind/new': 'release-fe',
  '/settings/integrations/:kind/show': 'release-fe',
  '/settings/integrations/coderefs/:id/edit': 'release-fe',
  '/settings/integrations/destinations/:destinationId/edit': 'release-fe',
  '/settings/integrations/destinations/new': 'release-fe',
  '/settings/integrations/sentry/show': 'decision-science',
  '/settings/integrations/slack/:slackHookId/edit': 'release-fe',
  '/settings/integrations/slack/new': 'release-fe',
  '/settings/integrations/webhooks/:webhookId/edit': 'release-fe',
  '/settings/integrations/webhooks/new': 'release-fe',
  '/settings/members': 'release-fe',
  '/settings/members/:memberId': 'release-fe',
  '/settings/members/:memberId/history/*': 'release-fe',
  '/settings/members/:memberId/permissions': 'release-fe',
  '/settings/members/:memberId/settings': 'release-fe',
  '/settings/members/:memberId/teams': 'release-fe',
  '/settings/members/invite/:inviteEmail?': 'release-fe',
  '/settings/profile': 'release-fe',
  '/settings/projects': 'release-fe',
  '/settings/relay': 'release-fe',
  '/settings/relay/:id/edit': 'release-fe',
  '/settings/relay/new': 'release-fe',
  '/settings/roles': 'release-fe',
  '/settings/roles/project': 'release-fe',
  '/settings/roles/organization': 'release-fe',
  '/settings/roles/project/:key/view': 'release-fe',
  '/settings/roles/organization/:key/view': 'release-fe',
  '/settings/roles/:key/edit': 'release-fe',
  '/settings/roles/:key/view': 'release-fe',
  '/settings/roles/new': 'release-fe',
  '/settings/security': 'release-fe',
  '/settings/security/saml/config': 'release-fe',
  '/settings/teams': 'release-fe',
  '/settings/teams/:teamKey': 'release-fe',
  '/settings/teams/:teamKey/history/*': 'release-fe',
  '/settings/teams/:teamKey/members': 'release-fe',
  '/settings/teams/:teamKey/permissions': 'release-fe',
  '/settings/teams/:teamKey/settings': 'release-fe',
  '/settings/tokens/*': 'release-fe',
  '/settings/usage': 'release-fe',
  '/settings/usage/details/client': 'release-fe',
  '/settings/usage/details/data-export': 'release-fe',
  '/settings/usage/details/experiments': 'release-fe',
  '/settings/usage/details/host': 'release-fe',
  '/settings/usage/details/overview': 'release-fe',
  '/settings/usage/details/server': 'release-fe',
  '/verify-email': 'release-fe',
  '/projects/:projectKey/product-analytics': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/events': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/events/details/:id/edit': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/events/new': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/cohorts': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/cohorts/:id': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/cohorts/:id/edit': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/cohorts/new': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/user-attributes': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/user-activity': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/dashboards': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/dashboards/:id': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/dashboards/:id/:nestedDashboardId': 'team-pa-fe',
  '/projects/:projectKey/product-analytics/visualizations/:id': 'team-pa-fe',
};
