import { isABTestingEnabled } from '@gonfalon/dogfood-flags';
import { Rule, VariationOrRollout } from '@gonfalon/flags';

export function hasExistingExperimentRollouts(rules: Rule[] | undefined, fallthrough: VariationOrRollout | undefined) {
  if (!isABTestingEnabled()) {
    return false;
  }

  if ((rules ?? []).some((r) => r.rollout?.experimentAllocation !== undefined)) {
    return true;
  }

  return fallthrough?.rollout?.experimentAllocation !== undefined;
}
