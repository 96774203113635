import { endOfDay, isValid, startOfDay } from 'date-fns';

import { coerceClauseValues } from './coerceClauseValues';
import { getClauseExpectedValueType } from './getClauseExpectedValueType';
import { Clause, ClauseValue } from './types';

export function updateClauseOperator(clause: Clause, op: string, negate: boolean): Clause {
  const previousExpectedType = getClauseExpectedValueType(clause);

  const nextClause = { ...clause, op, negate };

  const expectedType = getClauseExpectedValueType(nextClause);

  if (op === 'before' || op === 'after') {
    const firstValue = clause.values[0] as ClauseValue | undefined;
    if (firstValue === undefined || typeof firstValue === 'boolean') {
      return nextClause;
    }
    const firstValueDate = new Date(firstValue);
    const date = isValid(firstValueDate) ? firstValueDate : new Date();
    const newDate = op === 'before' ? startOfDay(date).valueOf() : endOfDay(date).valueOf();
    nextClause.values = [newDate];
  } else if (expectedType !== previousExpectedType) {
    // if the expected type has changed, we should coerce the values to the expected type for the new operator
    nextClause.values = coerceClauseValues(nextClause.values, { expectedType });
  }

  return nextClause;
}
