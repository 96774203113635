import { type GetEnvironmentsByProjectQueryParams } from '@gonfalon/openapi';

export function seralizeEnvironmentsSearchParamsToBrowser(filters: GetEnvironmentsByProjectQueryParams) {
  const params = new URLSearchParams();
  if (filters.offset) {
    params.set('offset', filters.offset.toString());
  }
  if (filters.filter?.query) {
    params.set('query', filters.filter.query);
  }

  if (filters.filter?.tags && filters.filter.tags.length > 0) {
    params.set('tags', filters.filter.tags.join(','));
  }

  // Note: remove this when we retire the environments list, this exists for backwards compatibility
  if (filters.textFilter) {
    params.set('textFilter', filters.textFilter);
  }

  return params;
}
