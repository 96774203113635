import { FeatureFlagConfig } from './types';

export function getFlagRuleVariationIndices(config: FeatureFlagConfig) {
  const ruleVariationIndices: number[] = [];

  config.rules?.forEach((r) => {
    if (r.rollout?.variations?.length) {
      // filter out rules where weight is 0 to match backend logic
      r.rollout.variations.forEach((wv) => {
        if (wv.weight && wv.weight !== 0) {
          ruleVariationIndices.push(wv.variation);
        }
      });
    }
    if (r.variation !== undefined) {
      ruleVariationIndices.push(r.variation);
    }
  });

  return ruleVariationIndices;
}
