import { Clause } from './types';

/**
 * returns a copy of the clause with its attribute updated, as well as its op, negate, and values properties, if necessary
 */
export function updateClauseAttribute(clause: Clause, attr: string): Clause {
  if (attr === clause.attribute) {
    return clause;
  }

  const isSegmentAttr = attr.includes('segmentMatch');

  let op = clause.op;
  if (!op) {
    // if setting a new attribute, return the default for segment vs non-segment attributes
    op = isSegmentAttr ? 'segmentMatch' : 'in';
  } else if (isSegmentAttr) {
    // if switching to segment attribute, always default to segmentMatch
    op = 'segmentMatch';
  } else if (attr === 'anonymous' || op === 'segmentMatch') {
    // if switching from segmentMatch attribute to another attribute or switching to anonymous attribute, default to in operator
    op = 'in';
  }

  let negate = clause.negate;
  if (!clause.attribute) {
    negate = attr.startsWith('not-') ? true : negate;
  } else if (clause.attribute.startsWith('not-') && !attr.startsWith('not-')) {
    negate = false;
  } else if (!clause.attribute.startsWith('not-') && attr.startsWith('not-')) {
    negate = true;
  }

  let values = clause.values;
  if (
    clause.attribute &&
    ((clause.attribute.includes('segmentMatch') && !attr.includes('segmentMatch')) ||
      (!clause.attribute.includes('segmentMatch') && attr.includes('segmentMatch')))
  ) {
    values = [];
  }

  return { ...clause, attribute: attr, op, negate, values };
}
