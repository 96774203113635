import { type JSX, lazy, Suspense, useEffect, useLayoutEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  matchRoutes,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  To,
  useLocation,
  useNavigate,
} from 'react-router';
import { datadogRum } from '@datadog/browser-rum-slim';
import { useTrackClientSidePageViews } from '@gonfalon/analytics';
import { profile } from '@gonfalon/bootstrap-data';
import {
  enableManuallyTrackedDdrumViews,
  enforceEmailVerificationLandingPage,
  spaPageViewsForSegmentCom,
} from '@gonfalon/dogfood-flags';
import { matchNewHires, toVerifyEmail } from '@gonfalon/navigator';
// The only modules allowed to consume the provider are application entry points.
// eslint-disable-next-line no-restricted-imports
import { ReactQueryClientProvider } from '@gonfalon/react-query-client';
import { generateMatchingAppPathPattern, RoutePatternContext, useMatchingAppPathPattern } from '@gonfalon/router';
import { setRouteContext } from '@gonfalon/telemetry';
import { ThemeProvider } from '@gonfalon/theme';
import { RouterProvider as ReactAriaRouterProvider, useHref } from '@launchpad-ui/components';
import { HelmetProvider } from 'helmet';
import { Store } from 'redux';
import { appRouteOwnership } from 'routes/appRouteOwnership';
import { appRoutes } from 'routes/appRoutes';

import { updateLocation } from 'actions/router';
import { FocusTrapProvider } from 'components/FocusTrapProvider';
import { IconProvider } from 'components/IconProvider';
import { useDispatch } from 'hooks/useDispatch';
import Logger from 'utils/logUtils';

const bundleName = window.__bundle__;
const logger = Logger.get('appContext');

type AppProvidersProps = { reduxStore: Store };

function getRoutelessComponentForBundle(_bundleName: string) {
  switch (_bundleName) {
    case 'oauth2Authorize':
      return lazy(
        async () => import(/* webpackChunkName: "oauth2Authorize.entry" */ 'components/OAuth2AuthorizeContainer'),
      );
    case 'oauthProviderConfig':
      return lazy(
        async () => import(/* webpackChunkName: "oauthProviderConfig.entry" */ 'components/OAuthProviderConfig'),
      );
    default:
      logger.warn("If nothing is loading, make sure you've added a route or added to getRoutelessComponentForBundle");
  }
}

const createRouter = () => {
  const routes = appRoutes();

  // We have several concerns that need to be managed here:
  // - update Redux state when the route changes (LocationStateWithLayoutEffect)
  // - set up a RoutePatternContext.Provider that provides generic information about the current route for analytics
  //   and our Datadog configurations (RoutePatternProvider)
  // - choose a routeless bundle component if a window.__bundle__ was set (AppLayoutRoute)
  // - navigate to email verification if the user is unverified and the `enforce-email-verification-landing-page` flag
  //   is on (AppLayoutRoute)
  //
  // ...all of these concerns require being within a router context - and RoutePatternProvider needs a static list of
  // route definitions to function properly - so we wrap the actual route definitions in a layout route, which in turn
  // composes these pieces together and threads the required props down.
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppLayoutRoute routes={routes} />} hydrateFallbackElement={null}>
        {routes}
      </Route>,
    ),
  );

  if (enableManuallyTrackedDdrumViews()) {
    //TODO: replace when soft navigations are added to core web vitals: https://developer.chrome.com/docs/web-platform/soft-navigations-experiment
    router.subscribe(({ location, navigation }) => {
      //A new view will start for every pathname change. This does not include param changes. If we want to create new views for param changes, we need to compare location.key instead.
      if (navigation.state === 'loading' && location.pathname !== navigation.location.pathname) {
        const matches = matchRoutes(router.routes, location);
        const pathPattern = generateMatchingAppPathPattern(matches, {});
        //starts a new datadog view for the path you are navigating to so that the timings are correctly attributed to the view.
        datadogRum.startView({ name: navigation.location.pathname });
        //The view is starting prior to actual navigation and render of the view, so we need to set the global context earlier.
        if (pathPattern) {
          const owner = appRouteOwnership[pathPattern];
          setRouteContext(pathPattern, owner);
        }
      }
    });
  }
  return router;
};

function LocationStateWithLayoutEffect() {
  const location = useLocation();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(updateLocation(location));
  }, [location, dispatch]);

  return null;
}

function RoutePatternProvider(props: { routes: JSX.Element }) {
  const pathPattern = useMatchingAppPathPattern(props.routes);

  useEffect(() => {
    if (pathPattern) {
      const owner = appRouteOwnership[pathPattern];
      setRouteContext(pathPattern, owner);
    }
  }, [pathPattern]);

  return (
    <RoutePatternContext.Provider value={pathPattern}>
      <Outlet />
    </RoutePatternContext.Provider>
  );
}

function AppLayoutRoute(props: { routes: JSX.Element }) {
  // We combined the newHires entrypoint with the app entrypoint and as a
  // result need to allow an exception for visiting the new hires route without
  // boostrap data for the profile. For all other routes, redirect to email
  // verification if profile data is missing and the
  // `enforce-email-verification-landing-page` flag is on.
  const isNewHiresRoute = matchNewHires(useLocation());
  const navigate = useNavigate();

  useTrackClientSidePageViews({ routes: props.routes, enabled: spaPageViewsForSegmentCom() });

  if (!isNewHiresRoute && !profile()?._verified && enforceEmailVerificationLandingPage()) {
    return <Navigate to={toVerifyEmail()} />;
  }

  return (
    <>
      <LocationStateWithLayoutEffect />
      <ReactAriaRouterProvider navigate={navigate} useHref={useHref as (href: To) => string}>
        <RoutePatternProvider routes={props.routes} />
      </ReactAriaRouterProvider>
    </>
  );
}

function AppProvidersBase(props: AppProvidersProps) {
  let content: JSX.Element;

  const RoutelessContentComponent = getRoutelessComponentForBundle(bundleName);
  if (RoutelessContentComponent !== undefined) {
    content = (
      <Suspense fallback={null}>
        <RoutelessContentComponent />
      </Suspense>
    );
  } else {
    const router = createRouter();
    content = <RouterProvider router={router} />;
  }

  return (
    <ReactQueryClientProvider>
      <ReduxProvider store={props.reduxStore}>
        <IconProvider>
          <FocusTrapProvider>
            <ThemeProvider>
              <HelmetProvider>{content}</HelmetProvider>
            </ThemeProvider>
          </FocusTrapProvider>
        </IconProvider>
      </ReduxProvider>
    </ReactQueryClientProvider>
  );
}

/* eslint-disable import/no-default-export */
export default function AppProviders(props: AppProvidersProps) {
  return <AppProvidersBase {...props} />;
}
