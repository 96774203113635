import { type GetEnvironmentsByProjectQueryParams } from '@gonfalon/openapi';
import { createSearchParamsHook } from '@gonfalon/router';

import { parseEnvironmentsSearchParamsFromBrowser } from './parseEnvironmentsSearchParamsFromBrowser';
import { seralizeEnvironmentsSearchParamsToBrowser } from './seralizeEnvironmentsSearchParamsToBrowser';

export const useEnvironmentsParams = createSearchParamsHook<GetEnvironmentsByProjectQueryParams>({
  parse: parseEnvironmentsSearchParamsFromBrowser,
  serialize: seralizeEnvironmentsSearchParamsToBrowser,
});
