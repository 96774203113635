/* eslint-disable @typescript-eslint/naming-convention */
import { createTrackerForCategory } from '@gonfalon/analytics';
import { allClientSideFlags, baseUri, planInfo, subscriptionState, userId } from '@gonfalon/constants';
import { getDogfoodClient } from '@gonfalon/dogfood-client';
import { isBeamerEnabled, isBeamerSegmentsEnabled } from '@gonfalon/dogfood-flags';
import { BeamerConfig } from '@gonfalon/types';

import { AnalyticAttributes, getComponentAnalytics } from './analyticsUtils';

export const getDogfoodFlags = () => {
  let dogfoodClient;
  try {
    dogfoodClient = getDogfoodClient();
  } catch (error) {
    return allClientSideFlags();
  }
  return dogfoodClient.allFlags();
};

function onBeamerWindowOpen() {
  const component = 'RootLearningCenterDrawer';

  trackBeamerDrawerLoaded(
    getComponentAnalytics({
      component,
      cta: "What's new",
      type: 'button',
    }),
  );
}

export function initBeamer() {
  if (!isBeamerEnabled() || !userId()) {
    return;
  }

  let beamer_config: BeamerConfig = (window.beamer_config = {
    product_id: 'SqdFEkqf11385', //DO NOT CHANGE: This is your product code on Beamer
    selector: 'beamerButton',
    button: false,
    user_id: userId(),
    environment: baseUri(),
  });

  beamer_config = window.beamer_config = {
    ...beamer_config,
    // When the top nav is enabled, we display the counter at the top level,
    // and next to the menu item that will open the Beamer panel.
    selector: '.beamer-button',
    button_position: 'top-right',
    counter: true,
    bounce: false,
    onopen: onBeamerWindowOpen,
  };

  if (isBeamerSegmentsEnabled()) {
    beamer_config.plan_info = planInfo();
    beamer_config.subscription_state = subscriptionState();

    // Casting to simpler object here since we do not get about specific values
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const flags: { [key: string]: any } = getDogfoodFlags();
    Object.keys(flags).forEach((flag) => {
      const validFlag = !!flags[flag];

      if (validFlag && typeof flags[flag] !== 'object') {
        // Convert camelCase to snake_case
        beamer_config[flag.replace(/([A-Z])/g, '_$1').toLowerCase()] = flags[flag];
      }
    });
  }

  const beamerScript = document.createElement('script');
  beamerScript.type = 'application/javascript';
  beamerScript.src = 'https://app.getbeamer.com/js/beamer-embed.js';

  document.body.appendChild(beamerScript);
}

const trackBeamer = createTrackerForCategory('Beamer');

const trackBeamerDrawerLoaded = (analyticsAttributes: AnalyticAttributes) =>
  trackBeamer("What's New Loaded", analyticsAttributes);
