import { Member } from '@gonfalon/members';
import { checkAccess } from '@gonfalon/permissions';

import { getFlagConfigurationForEnvironment } from './getFlagConfigurationForEnvironment';
import { FeatureFlag } from './types';

// Document flag actions here.
// Ideally this would come from OpenAPI, but
// our docs aren't structured that way yet.
export type Action =
  | 'addReleasePipeline'
  | 'bypassRequiredApproval'
  | 'createApprovalRequest'
  | 'updateFlagSalt'
  | 'updateFlagConfigMigrationSettings'
  | 'updateTrackEvents'
  // Why do these require an environment key? :(
  | 'updateGlobalArchived'
  | 'updateDeprecated'
  | 'updateTags'
  | 'updateMaintainer'
  | 'createFlagLink'
  | 'deleteFlagLink'
  | 'manageFlagFollowers'
  | 'stopMeasuredRolloutOnFallthrough'
  | 'stopMeasuredRolloutOnRule'
  | 'updateReleasePhaseStatus'
  | 'removeReleasePipeline'
  | 'stopProgressiveRollout';

export function checkFlagConfigurationAccess(
  member: Member,
  action: Action,
  flag: FeatureFlag,
  environmentKey: string,
) {
  const config = getFlagConfigurationForEnvironment(flag, environmentKey);
  const access = config._access;
  return checkAccess(member, access, action);
}

export const checkFlagAccessByMultipleEnvironments =
  (flag: FeatureFlag, envKeys: string[], profile: Member) => (action: Action) => {
    const atLeastOneEnvironmentDenied = envKeys.some((key: string) => {
      const environmentLevelCheck = checkFlagConfigurationAccess(profile, action, flag, key);
      return !environmentLevelCheck.isAllowed;
    });
    return atLeastOneEnvironmentDenied ? { isAllowed: false } : { isAllowed: true };
  };
