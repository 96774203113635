import { type ComponentProps } from 'react';
import { Diamond } from '@gonfalon/icons';
import clsx from 'clsx';

import { getVariationFillColor } from '../getVariationFillColor';
import { type Variation, type WeightedVariation } from '../types';
import { VariationColor } from '../VariationColor';

import styles from './VariationStack.module.css';

const defaultDiamondCount = 4;

export function VariationStack({
  variations,
  start = 0,
  limit = defaultDiamondCount,
  size = 'small',
  className,
}: {
  variations: Array<WeightedVariation | Variation | { variationIndex: number }>;
  start?: number;
  limit?: number;
  size?: ComponentProps<typeof VariationColor>['size'];
  className?: string;
}) {
  const someVariations = variations.slice(start, start + limit);
  const remainingVariations = variations.slice(start + limit);

  const indices: number[] = [];
  for (const [index, variation] of someVariations.entries()) {
    if ('variationIndex' in variation) {
      indices.push(variation.variationIndex);
    } else if ('variation' in variation) {
      indices.push(variation.variation);
    } else {
      indices.push(index);
    }
  }

  return (
    <div className={clsx(styles.colors, className)} data-test-id="variation-stack">
      {indices.map((index) => (
        <Diamond
          key={`variation-${index}`}
          size={size}
          className={clsx(styles.color, size !== undefined && size !== null ? styles[size] : undefined)}
          stroke={variations.length > 1 && index > 0 ? 'var(--lp-color-bg-ui-primary)' : undefined}
          fill={getVariationFillColor(index)}
        />
      ))}
      {remainingVariations.length > 0 && start === 0 ? (
        <span className={styles.remaining}>+{remainingVariations.length}</span>
      ) : undefined}
      {start > 0 && <span>+ {start}</span>}
    </div>
  );
}
