import { isEqual } from '@gonfalon/es6-utils';

import { findVariationByValue } from './findVariationByValue';
import { getVariationType } from './getVariationType';
import { FeatureFlagStatus, Variation } from './types';

export function getFlagDefaultVariation(variations: Variation[], status?: FeatureFlagStatus) {
  if (!status || status.default === null) {
    return;
  }

  if (getVariationType(variations) === 'json' && typeof status.default === 'object') {
    return variations.find((v) => isEqual(v.value, status.default));
  }

  return findVariationByValue(status.default, variations);
}
