import { isABTestingEnabled } from '@gonfalon/dogfood-flags';

import { ExperimentInfoRep } from './types';

export function hasActiveExperiments(environmentKey: string, experiments: ExperimentInfoRep | undefined) {
  if (!isABTestingEnabled()) {
    return false;
  }

  const hasAnActiveExperiment =
    experiments?.items.some((metric) => {
      const envSetting = metric._environmentSettings?.[environmentKey];
      return !!(envSetting && envSetting.startDate && !envSetting.stopDate);
    }) ?? false;

  return hasAnActiveExperiment;
}
