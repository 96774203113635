import { isMobileRule } from './isMobileRule';
import { isSegmentRule } from './isSegmentRule';
import { Rule } from './types';

export const getRuleKind = (rule: Rule): 'mobile' | 'segment' | 'custom' => {
  if (isMobileRule(rule)) {
    return 'mobile';
  } else if (isSegmentRule(rule)) {
    return 'segment';
  }
  return 'custom';
};
