import { useMemo } from 'react';
import { isCommandBarEnabled } from '@gonfalon/dogfood-flags';
import { noop } from '@gonfalon/es6-utils';

const addMetadataStub: (typeof window.CommandBar)['addMetadata'] = noop;
const openStub: (typeof window.CommandBar)['open'] = noop;
const addEventSubscriberStub: (typeof window.CommandBar)['addEventSubscriber'] = async () => Promise.resolve(noop);
const triggerChecklistStub: (typeof window.CommandBar)['triggerChecklist'] = noop;

export function useCommandBar() {
  // eslint-disable-next-line no-restricted-properties
  const isAvailable = isCommandBarEnabled() && typeof window.CommandBar !== 'undefined';

  const sdk = useMemo(() => {
    // eslint-disable-next-line no-restricted-properties
    const addMetadata = isAvailable ? window.CommandBar.addMetadata.bind(window.CommandBar) : addMetadataStub;
    // eslint-disable-next-line no-restricted-properties
    const open = isAvailable ? () => window.CommandBar.open() : openStub;
    const addEventSubscriber = isAvailable
      ? // eslint-disable-next-line no-restricted-properties
        window.CommandBar.addEventSubscriber.bind(window.CommandBar)
      : addEventSubscriberStub;

    const triggerChecklist = isAvailable
      ? // eslint-disable-next-line no-restricted-properties
        (id: number) => window.CommandBar.triggerChecklist(id)
      : triggerChecklistStub;

    return {
      addMetadata,
      open,
      addEventSubscriber,
      triggerChecklist,
    };
  }, [isAvailable]);

  return sdk;
}
