import { schemas } from '@gonfalon/openapi';
import clsx from 'clsx';

import { getFlagVariationDisplayValue } from '../getFlagVariationDisplayValue';
import { VariationColor } from '../VariationColor';

import styles from './VariationDisplay.module.css';

type Variation = schemas['Variation'];

type DisplayOptions = NonNullable<Parameters<typeof getFlagVariationDisplayValue>[1]>;

export type VariationDisplayProps = {
  variation: Variation;
  variationIndex: number;
  className?: string;
} & DisplayOptions;

export function VariationDisplay({ variation, variationIndex, className, ...displayOptions }: VariationDisplayProps) {
  return (
    <div className={clsx(styles.variation, className)}>
      <VariationColor variationIndex={variationIndex} className={styles.color} />
      <div className={styles.display}>{getFlagVariationDisplayValue(variation, displayOptions)}</div>
    </div>
  );
}
