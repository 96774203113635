import { getCountOfTargetsForVariation } from './getCountOfTargetsForVariation';
import { FeatureFlagConfig, Variation } from './types';

// each variation is returned wrapped in an object containing the variation, its index, and its target count
export function getVariationsWithoutTargets(variations: Variation[], flagConfig: FeatureFlagConfig) {
  return variations
    .map((variation, variationIndex) => ({
      variation,
      variationIndex,
      targetCount: getCountOfTargetsForVariation(flagConfig, variationIndex),
    }))
    .filter(({ targetCount }) => targetCount === 0);
}
