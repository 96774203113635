import { queryfilterToString } from '@gonfalon/queryfilter';

import { UIContextsView } from './types';

export function serializeUIContextsViewForBrowser(ui: UIContextsView) {
  const searchParams = new URLSearchParams();

  if (ui.filter) {
    searchParams.set('filter', encodeURIComponent(queryfilterToString(ui.filter)));
  }

  return searchParams;
}
