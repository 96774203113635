import { isString } from '@gonfalon/es6-utils';
import { coerceToType, couldBeBoolean, couldBeNumber } from '@gonfalon/types';

import { ClauseValue } from './types';

export const getClauseCoercedValue = (value: ClauseValue, expectedType?: 'string' | 'number') => {
  if (expectedType) {
    return coerceToType(value, expectedType);
  }

  if (couldBeNumber(value)) {
    if (isString(value) && value.length > 16) {
      //https://www.ecma-international.org/ecma-262/5.1/#sec-8.5. store large numbers as strings to avoid rounding errors
      return value;
    } else if (coerceToType(value, 'number').toString() !== value) {
      // don't prevent the ability to enter strings with leading or trailing zeroes
      return value;
    }
    return coerceToType(value, 'number');
  } else if (couldBeBoolean(value)) {
    return coerceToType(value, 'boolean');
  } else {
    return value;
  }
};
