import { type AnalyticEventData, createTrackerForCategory, trackWithDetails } from '@gonfalon/analytics';

import { FlagTargetingEventInfo, MetricsTrackEventInfo, MigrationChartTypes, NewFlagCreationEventInfo } from './types';

export const trackCreateNewFlagClicked = ({ component, type, cta }: AnalyticEventData) =>
  trackWithDetails({
    component,
    type,
    cta,
    event: 'Create New Flag Clicked',
    category: 'New Flag Created',
  });

export const trackNewFlagCreationEvent = (event: string, attributes?: NewFlagCreationEventInfo) =>
  createTrackerForCategory('New Flag Creation')(event, attributes);

export const trackNewFlagCreationFormSubmitted = (attributes?: NewFlagCreationEventInfo) =>
  trackNewFlagCreationEvent('New Flag Creation Form Submitted', attributes);

export const trackNewFlagCreationViewed = () => trackNewFlagCreationEvent('New Flag Creation Viewed');

export const trackNewFlagCreationCancelButtonClicked = () =>
  trackNewFlagCreationEvent('New Flag Creation Cancel Button Clicked');

export const trackNewFlagCreationDefaultKeyChanged = (flagKey: string) =>
  trackNewFlagCreationEvent('New Flag Creation Default Flag Key Changed', { flagKey });

export const trackMigrationMetricsEvent = (event: string, attributes?: MetricsTrackEventInfo) =>
  createTrackerForCategory('Migration metrics')(event, attributes);

export const trackMetricsChartChanged = (
  selectedMetric: MigrationChartTypes,
  stageCount?: number,
  contextKind?: string,
) => trackMigrationMetricsEvent('Metrics Chart Changed', { selectedMetric, stageCount, contextKind });

export const trackFlagLinksEvent = createTrackerForCategory('FlagLinks');

export const trackRuleExclusionEvent = createTrackerForCategory('RuleExclusion');

const trackFlagTargetingEvent = (event: string, attributes?: FlagTargetingEventInfo) =>
  createTrackerForCategory('FlagsTargeting')(event, attributes);

export const trackEmptyStateSegmentTargetingClicked = () =>
  trackFlagTargetingEvent('Empty State Segment Targeting Clicked');

export const trackEmptyStateMobileTargetingClicked = () =>
  trackFlagTargetingEvent('Empty State Mobile Targeting Clicked');

export const trackEmptyStateOptionClicked = (targetKind: string) =>
  trackFlagTargetingEvent('Empty State Option Clicked', { targetKind });

export const trackTargetingRuleValuesShowMoreClicked = () =>
  trackFlagTargetingEvent('Targeting Rule Values Show More Clicked');
export const trackTargetingRuleValuesShowLessClicked = () =>
  trackFlagTargetingEvent('Targeting Rule Values Show Less Clicked');

export const trackIndividualTargetValuesShowClicked = () => trackFlagTargetingEvent('Individual Values Show Clicked');
export const trackIndividualTargetValuesShowLessClicked = () =>
  trackFlagTargetingEvent('Individual Target Values Show Less Clicked');

export const trackFlagDisplayPreferencesEvent = createTrackerForCategory('Flag Display Preferences');
export const trackFlagFiltersEvent = createTrackerForCategory('Flag Filters');
export const trackReadyToArchiveModalEvent = createTrackerForCategory('Ready to Archive Modal');
export const trackReadyForCodeRemovalModalEvent = createTrackerForCategory('Ready for Code Removal Modal');
export const trackArchiveWithoutCodeRemovalModal = createTrackerForCategory('Archive Without Code Removal Modal');
