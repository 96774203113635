import { isJSONValue } from './isJSONValue';

export type StringifyValueOptions = {
  displayValueForEmptyStrings?: boolean;
  allowScientificNotation?: boolean;
};

/**
 * Attention:
 * Don't use this function with immutable.js, JSON.stringify(val) vs val.toString() behavior is different.
 *
 * examples:
 * - Map { "a": 1, "b": 2 } => '{"a":1,"b":2}'
 * val.toString()
 * - Map { "a": 1, "b": 2 } => 'Map { "a": 1, "b": 2 }'
 *
 * so a comparision of JSON.stringify(val) === val.toString() will always be false. side note: immutable mixes up object key ordering so the stringified values will be different
 */
// TODO: audit all uses of this function with comparison logic and refactor
export function stringifyValue(val: unknown, options?: StringifyValueOptions): string {
  if (val === null || val === undefined) {
    return 'null';
  }
  if (val === '' && options?.displayValueForEmptyStrings) {
    return '(empty string)';
  }

  if (isJSONValue(val)) {
    return JSON.stringify(val);
  }

  if (typeof val === 'number' && !options?.allowScientificNotation) {
    // this will convert a number that could be in scientific notation, like 1e-7, to the string '0.0000001'
    // 21 is the maximum number of significant digits allowed by Intl.NumberFormat
    // Note: using { roundingPriority: "morePrecision" } as an option also does this, but it is not supported in TypeScript natively as of v5.1.6
    return val.toLocaleString('fullwide', { useGrouping: false, maximumSignificantDigits: 21 });
  }

  return val.toString();
}
