import { CSSProperties } from 'react';
import clsx from 'clsx';

import styles from './EnvironmentMarker.module.css';

export function EnvironmentMarker({ color, className }: { color: string; className?: string }) {
  const sanitizedColor = color.startsWith('#') ? color : `#${color}`;
  return (
    <span
      role="presentation"
      className={clsx(className, styles.marker)}
      style={{ '--marker-color': sanitizedColor } as CSSProperties}
    />
  );
}
