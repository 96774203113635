import { isABTestingEnabled } from '@gonfalon/dogfood-flags';

import { ExperimentInfoRep } from './types';

export function hasExperiments(experiments: ExperimentInfoRep, goalIds: string[] | undefined) {
  if (!isABTestingEnabled()) {
    return false;
  }

  const hasNewExperiments = !!(experiments && experiments.items.length);
  return (goalIds ?? []).length > 0 || hasNewExperiments;
}
