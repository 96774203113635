import { getAllTargetsForVariation } from './getAllTargetsForVariation';
import { FeatureFlagConfig } from './types';

export function getCountOfTargetsForVariation(
  flagConfig: Pick<FeatureFlagConfig, 'contextTargets' | 'targets'>,
  variationIdx: number,
) {
  const targets = getAllTargetsForVariation(flagConfig, variationIdx);
  return targets.reduce((acc, target) => acc + target.values.length, 0);
}
