import { CONTEXT, CONTEXT_KIND_ATTRIBUTE } from '@gonfalon/context-kinds';
import { DateFormat } from '@gonfalon/format';
import { format } from 'date-fns';

import { segmentAttributeReadableValue } from './constants';
import { convertSegmentValueKeysToNames } from './convertSegmentValueKeysToNames';
import { getContextKind } from './getContextKind';
import { getOperationLabel } from './getOperationLabel';
import { hasSegmentOp } from './hasSegmentOp';
import { isDateOp } from './isDateOp';
import { isMobileClause } from './isMobileClause';
import { Clause, Segment } from './types';

export function getClauseComponents(
  clause: Clause,
  segments: Segment[],
  attribute: string,
  // could pull this out into a separate package for user-attributes
  mobileAttributesMapping: { [key: string]: string },
) {
  let contextKind = getContextKind(clause);
  let attr = attribute;
  let operation = '';
  let values = clause.values;
  let valuesString = clause.values.join(', ');

  if (hasSegmentOp(clause)) {
    attr = segmentAttributeReadableValue[attr as 'segmentMatch' | 'not-segmentMatch'];
    values = convertSegmentValueKeysToNames(values, segments);
    valuesString = values.join(', ');
    contextKind = CONTEXT;
  } else if (isMobileClause(clause)) {
    attr = mobileAttributesMapping[attr] || attr;
    operation = getOperationLabel(clause);
  } else if (attr === CONTEXT_KIND_ATTRIBUTE) {
    attr = 'Context kind'; // for legibility in instructions
    contextKind = '';
    operation = getOperationLabel(clause);
  } else {
    if (isDateOp(clause.op)) {
      //format date input value (there will always be one value)
      const date = new Date(values[0] as number);
      valuesString = isNaN(date.valueOf()) ? 'invalid date' : format(date, DateFormat.MMM_D_YYYY_H_MM_A);
      values = [valuesString];
    }
    operation = getOperationLabel(clause);
  }

  return {
    contextKind,
    attribute: attr,
    operation,
    values,
    valuesString,
  };
}
