import { countTargetingRules } from './countTargetingRules';
import { countTargetingRulesTrackingEvents } from './countTargetingRulesTrackingEvents';
import { isTrackingAnyEvents } from './isTrackingAnyEvents';
import { FeatureFlagConfig } from './types';

export function countTrackEvents(config: FeatureFlagConfig, hasExperiments: boolean) {
  const trackEvents = config.trackEvents;
  const totalTargetingRules = countTargetingRules(config);
  const totalRulesSendingEvents = isTrackingAnyEvents(trackEvents, hasExperiments, config)
    ? totalTargetingRules
    : countTargetingRulesTrackingEvents(config);

  return { active: totalRulesSendingEvents, total: totalTargetingRules };
}
