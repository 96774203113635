type FlagErrorCode = 'missing_environment';

export class FlagError extends Error {
  name = 'FlagError';
  code: FlagErrorCode;
  message: string;

  constructor({ code, message }: { code: FlagErrorCode; message: string }) {
    super(message);
    this.code = code;
    this.message = message;
  }

  toJSON() {
    return { code: this.code, message: this.message };
  }
}

export function createMissingEnvironmentError(environmentKey: string): FlagError {
  return new FlagError({
    code: 'missing_environment',
    message: `No configuration found for environment "${environmentKey}"`,
  });
}

export function isFlagError(error: unknown): error is FlagError {
  return error instanceof FlagError;
}
