import { schemas } from '@gonfalon/openapi';

export type FeatureFlags = schemas['FeatureFlags'];

export type FeatureFlag = schemas['FeatureFlag'];

export type ArchiveCheckData = schemas['StaleFlagData'];
export type FlagSummary = schemas['FlagSummary'];

export type FlagLinkRep = schemas['FlagLinkRep'];

export type FlagDefaultsRep = schemas['flagDefaultsRep'];

export type ProjectRep = schemas['ProjectRep'];

export type NamingConventions = ProjectRep['namingConvention'];

/**
 * The type of a process's `statusInfo` is currently `unknown`.
 * This is serving largely as a placeholder until either the OpenAPI spec can be more specific,
 * but the status values are what can currently be returned from the API.
 */
export type FeatureFlagConfigProcess = {
  kind: 'release';
  statusInfo: {
    id: string;
    phases: Array<{
      id: string;
      audience: {
        id: string;
        status:
          | 'completed'
          | 'ready-to-start'
          | 'rolling-out'
          | 'approval-pending-review'
          | 'approval-pending-apply'
          | 'monitoring';
      };
      status: 'not-started' | 'ready-to-start' | 'active' | 'completed';
    }>;
    status: 'not-started' | 'active' | 'completed' | 'canceled' | 'unknown';
  };
};

export type FeatureFlagConfig = schemas['FeatureFlagConfig'];

export type Rule = schemas['Rule'];

export type SegmentRule = schemas['UserSegmentRule'];

export type Clause = schemas['Clause'];

export type FeatureFlagStatus = schemas['FeatureFlagStatus'];

export type Variation = schemas['Variation'];
export type VariationType = 'string' | 'boolean' | 'number' | 'json';

export type WeightedVariation = schemas['WeightedVariation'];

export type VariationOrRollout = schemas['VariationOrRolloutRep'];

export enum FlagFilterStateKind {
  LIVE = 'live',
  ARCHIVED = 'archived',
  DEPRECATED = 'deprecated',
}

export enum ContextKind {
  USER = 'user',
  APPLICATION = 'ld_application',
  DEVICE = 'ld_device',
}

export type NewFlagCreationEventInfo = {
  stageCount?: number;
  purpose?: string;
  prevFormStep?: number;
  newFormStep?: number;
  flagKey?: string;
};

export type FlagTargetingEventInfo = {
  targetKind?: string;
};

// Migration metrics types
export type MigrationMetricRep = schemas['MigrationMetricRep'];

type MetricBody = {
  differencePercentage?: number | null;
  betterSystem?: 'old' | 'new' | null;
  old?: number;
  new?: number;
};
// since summary is [key: string]: unknown; we are overriding it with the current shape iteration to fix our types
export type OverviewMetricsRep = schemas['OverviewMetricsRep'] & {
  summary: {
    consistencyRate: number;
    latencyMsP99: MetricBody;
    errorRate: MetricBody;
  };
};

export type MigrationChartTypes = 'consistency' | 'latency' | 'errors';
export type MigrationTimeTypes = '7 days' | '1 hour';

export type MetricsTrackEventInfo = {
  selectedMetric?: MigrationChartTypes;
  timeRangeValue?: MigrationTimeTypes;
  stageCount?: number;
  contextKind?: string;
};

export type ExperimentInfoRep = schemas['ExperimentInfoRep'];
export type ClientSideAvailability = schemas['ClientSideAvailability'];

// Flag templates types
export type FlagTemplate = schemas['FlagTemplateRepV2'] & { id?: string };
export type FlagTemplateV2Collection = schemas['FlagTemplateV2Collection'];

export type StatisticRep = schemas['StatisticRep'];

export type Workflow = schemas['CustomWorkflowOutput'];

// expiring targets types
export type ExpiringTarget = schemas['ExpiringTarget'];
export type ExpiringUserTarget = schemas['ExpiringUserTargetItem'];
export type ExpiringTargets = ExpiringTarget | ExpiringUserTarget;
