import { ContextKind } from './types';

export function isMobileContextKind(contextKind: string) {
  switch (contextKind as ContextKind) {
    case ContextKind.DEVICE:
    case ContextKind.APPLICATION:
      return true;
    default:
      return false;
  }
}
