import { useRouteLoaderData } from 'react-router';
import { InternalEnvironment } from '@gonfalon/environments';
import { Project } from '@gonfalon/projects';
import nullthrows from 'nullthrows';
import invariant from 'tiny-invariant';

import { type ProjectContext } from './projectContext';
import { projectDataSchema } from './types';

export type ProjectContextData = {
  context: ProjectContext;
  project: Project;
  environments: InternalEnvironment[];
  selectedEnvironment: InternalEnvironment;
};

/**
 * Provide access to the active project context.
 *
 * It may only be used inside of the route with the `project-context` id. That route
 * loads the project context, and synchronizes with the server when the context changes.
 */
export function useProjectContext(): ProjectContextData;
export function useProjectContext(options: { optional: boolean }): ProjectContextData | undefined;
export function useProjectContext({ optional }: { optional?: boolean } = {}): ProjectContextData | undefined {
  const loaderData = useRouteLoaderData('project-context');
  const result = projectDataSchema.safeParse(loaderData);

  if (!result.success && optional) {
    return;
  }

  invariant(result.success, 'useProjectContext may only be used under the project context loader');

  const currentProjectContext = result.data.context;
  const environmentData = result.data.environments;
  const projectContext = currentProjectContext;

  const selectedEnvironment = environmentData.find((e) => e.key === projectContext.selectedEnvironmentKey);
  invariant(selectedEnvironment, 'The selected environment key must be part of the project context.');

  const sortedEnvironments = Array.from(projectContext.environmentKeys).map((key) =>
    nullthrows(
      environmentData.find((it) => it.key === key),
      'Missing environment data for key',
    ),
  );

  return {
    context: projectContext,
    project: result.data.project,
    environments: sortedEnvironments,
    selectedEnvironment,
  } as const;
}
