import { isRebrandDiamondVariantEnabled } from '@gonfalon/dogfood-flags';

const rebrandVariationColors = ['#34B6D0', '#EEC340'];
const legacyVariationColors = ['#38D9A9', '#228BE6'];

/*
  Do not use this directly; use getVariationColorPalette instead.
  This array is missing the first two colors, which depend on
  the value of the isRebrandDiamondVariantEnabled flag.
*/
const dangerousPartialVariationColorPalette = [
  '#A34FDE',
  '#00B969',
  '#FF386B',
  '#282828',
  '#40E39C',
  '#CAA636',
  '#7A3BA7',
  '#3DD6F5',
  '#D9305B',
  '#BCBEC0',
];

const getVariationColorPalette = () => {
  const firstTwoColors = isRebrandDiamondVariantEnabled() ? rebrandVariationColors : legacyVariationColors;
  return [...firstTwoColors, ...dangerousPartialVariationColorPalette];
};

export { getVariationColorPalette };
