/* eslint-disable no-console */
// eslint-disable-next-line no-restricted-imports
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { initAnalyticsSession, initCanaryToken, initSegmentAndCandu } from '@gonfalon/analytics';
import { currentEnvironment, currentProject, profile } from '@gonfalon/bootstrap-data';
import { accountId } from '@gonfalon/constants';
import {
  commandBarReleasesEnvironment,
  frontendQueryCacheGcTime,
  getReactQueryStaleTime,
  isCommandBarEnabled,
} from '@gonfalon/dogfood-flags';
import { onDOMReady } from '@gonfalon/dom';
import { toHref, toLogin } from '@gonfalon/navigator';
import { initializeQueryClient } from '@gonfalon/react-query-client';
import {
  initializeAPIClient,
  initializeEnvironment,
  initializeProfile,
  initializeProject,
  isRESTAPIError,
} from '@gonfalon/rest-api';
import { trackPageLoadCustomMetrics } from '@gonfalon/telemetry';
import { init as initCommandBar } from 'commandbar';
import AppProviders from 'entryPoints/appProviders';
import thunk from 'redux-thunk';

import epicRegistry from 'epics/registry';
import beastMode from 'middleware/beastMode';
import timestamp from 'middleware/timestamp';
import { createStore } from 'reducers/createStore';
import { init as initializeAccountStorage } from 'sources/AccountLocalStorage';
import { trackOnPageSearchEvent } from 'utils/analyticsUtils';
import { initBeamer } from 'utils/beamerUtils';

import 'core-js/modules/es.array.to-spliced';
import 'core-js/modules/es.array.to-sorted';
import 'core-js/stable/structured-clone';
import 'reducers/router';

export const load = () => {
  const MAX_RETRIES = 3;
  initializeQueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        gcTime: frontendQueryCacheGcTime(),
        staleTime: getReactQueryStaleTime(),
        retry(failureCount, error) {
          if (isRESTAPIError(error)) {
            if (error.status === 404 || error.status === 403) {
              return false;
            }
          }
          return failureCount < MAX_RETRIES;
        },
      },
    },
  });

  const _project = currentProject();
  const _environment = currentEnvironment();
  const _profile = profile();

  if (_project) {
    initializeProject({ projectKey: _project.key, project: _project });

    if (_environment) {
      initializeEnvironment({
        projectKey: _project.key,
        environmentKey: _environment.key,
        environment: _environment,
      });
    }
  }

  if (_profile) {
    initializeProfile({ member: _profile });
  }

  const { store, reset } = createStore({
    middlewares: [thunk, epicRegistry.getMiddleware(), timestamp, beastMode],
  });

  window.__resetStore = reset;

  epicRegistry.run();

  trackOnPageSearchEvent();
  initAnalyticsSession();
  trackPageLoadCustomMetrics();
  initCanaryToken();
  initSegmentAndCandu();

  initializeAPIClient({
    onError: (error) => {
      if (!isRESTAPIError(error)) {
        return;
      }

      switch (error.status) {
        case 401:
          const currentLocation = window.location.href;
          const searchParams = new URLSearchParams({ redirect: currentLocation });
          // Unauthenticated pages have a different entry point, hence the full page reload.
          window.location.assign(toHref(toLogin({ search: searchParams })));
          break;
        default:
          break;
      }
    },
  });

  if (isCommandBarEnabled()) {
    initCommandBar('212e3939', { environment: commandBarReleasesEnvironment() });
  }

  onDOMReady(() => {
    const selector = '.ld-container';
    const container: HTMLElement | null = document.querySelector(selector);

    if (!container) {
      console.warn(`App container selector ${selector} not found, unable to render app`);
      return;
    }

    initializeAccountStorage(accountId() || '');

    if (process.env.NODE_ENV === 'development') {
      // The following rules are turned off because they cause excessive lag.
      const config = {
        rules: [
          { id: 'color-contrast', enabled: false },
          { id: 'duplicate-id-active', enabled: false },
          { id: 'duplicate-id-aria', enabled: false },
          { id: 'duplicate-id', enabled: false },
          { id: 'region', enabled: false },
        ],
        checks: [],
      };
      // Portal nodes such as tooltips & menus are excluded because they cause a massive lag
      const context = { exclude: [['[data-test-id="portal"]'], ['[class*="_Popover-content"]']] };
      import('@axe-core/react')
        .then(({ default: axe }) => {
          axe(React, ReactDOM, 10000, config, context).catch((err) =>
            console.warn('Error testing accessibility w/ axe', err),
          );
        })
        .catch((err) => console.warn('Error importing axe', err));
    }

    const root = createRoot(container);
    root.render(<AppProviders reduxStore={store} />);
    initBeamer();
  });
};
