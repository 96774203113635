import { type GetEnvironmentsByProjectQueryParams } from '@gonfalon/openapi';

export function parseEnvironmentsSearchParamsFromBrowser(searchParams: URLSearchParams) {
  const filters: GetEnvironmentsByProjectQueryParams = {};
  if (searchParams.has('offset')) {
    filters.offset = Number(searchParams.get('offset'));
  }

  if (searchParams.has('query')) {
    filters.filter = {
      query: searchParams.get('query') || undefined,
    };
  }

  if (searchParams.has('tags')) {
    filters.filter = {
      ...filters.filter,
      tags: searchParams.get('tags')?.split(','),
    };
  }

  // Note: remove this when we retire the environments list, this exists for backwards compatibility
  if (searchParams.has('textFilter')) {
    filters.textFilter = searchParams.get('textFilter') || undefined;
  }

  return filters;
}
