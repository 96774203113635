import { ReactNode, useEffect, useState } from 'react';
import { useCommandBar } from 'command-bar/useCommandBar';
import { FocusTrapContext } from 'launchpad';

export function FocusTrapProvider({ children }: { children: ReactNode }) {
  const [contain, setContain] = useState(true);
  const commandbar = useCommandBar();

  useEffect(() => {
    const unsubscribe = commandbar.addEventSubscriber((eventType: string) => {
      if (eventType === 'opened') {
        setContain(false);
      } else if (eventType === 'closed') {
        setContain(true);
      }
    });

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      unsubscribe.then((unsubscribeFn: $TSFixMe) => unsubscribeFn());
    };
  }, []);

  return <FocusTrapContext.Provider value={{ contain }}>{children}</FocusTrapContext.Provider>;
}
