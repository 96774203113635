import { enableMultipleFlagTypeDefaults } from '@gonfalon/dogfood-flags';
import { v4 } from 'uuid';

import { FlagTemplate, Variation } from './types';

export function reorderFlagTemplates(flagTemplate: FlagTemplate[]) {
  const customTemplate = flagTemplate.find((template) => template.key === 'custom') as FlagTemplate;
  const filteredTemplate = flagTemplate.filter((template) => template.key !== 'custom');

  return [customTemplate, ...filteredTemplate] as FlagTemplate[];
}

// TODO: Must clean up after we release flag templates
export function indexFlagTemplates(rawFlagTemplates: FlagTemplate[]) {
  const idsToFlagTemplatesMap = new Map<string, FlagTemplate>();

  let flagTemplates;
  if (enableMultipleFlagTypeDefaults()) {
    flagTemplates = reorderFlagTemplates(rawFlagTemplates);
  } else {
    flagTemplates = rawFlagTemplates;
  }
  flagTemplates.forEach((template) => {
    if (template.variations) {
      // have to do it this way in order to avoid TS yelling about template.variations being
      // potentially null or undefined
      const variationsWithIds = [] as Variation[];

      template.variations.forEach((element) => {
        variationsWithIds.push({ ...element, _id: v4(), value: String(element.value) });
      });

      // eslint-disable-next-line no-param-reassign
      template.variations = variationsWithIds;
    }
    if (template.key !== 'migration') {
      const keyForMap = enableMultipleFlagTypeDefaults() ? template.key : template.id;
      idsToFlagTemplatesMap.set(keyForMap ?? template.key, template);
    }
  });

  return idsToFlagTemplatesMap;
}
