import { z } from 'zod';

export const environmentSchema = z.object({
  _id: z.string(),
  _links: z.record(z.any()),
  _pubnub: z.object({
    channel: z.string(),
    cipherKey: z.string(),
  }),
  apiKey: z.string(),
  approvalSettings: z.any().optional(),
  color: z.string(),
  confirmChanges: z.boolean(),
  defaultTrackEvents: z.boolean(),
  defaultTtl: z.number(),
  key: z.string(),
  mobileKey: z.string(),
  name: z.string(),
  requireComments: z.boolean(),
  secureMode: z.boolean(),
  tags: z.array(z.string()),
  critical: z.boolean(),
});
