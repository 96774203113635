import { getFeatureFlagQuery } from '@gonfalon/rest-api';
import { Button } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import { useQuery } from '@tanstack/react-query';
import nullthrows from 'nullthrows';

import { getFlagConfigurationForEnvironment } from '../getFlagConfigurationForEnvironment';
import { isFlagOnForEnvironment } from '../isFlagOnForEnvironment';

import { FlagStatus } from './FlagStatus';
import { TargetingTag } from './TargetingTag';
import { FlagPickerProps } from './types';

import styles from './FlagPicker.module.css';

interface FlagPickerButtonProps extends Omit<FlagPickerProps, 'onSelectionChange'> {}

export function FlagPickerButton({
  projectKey,
  selectedFlagKey: featureFlagKey,
  environmentKey,
  includeStatus,
  includeTargeting,
  ...buttonProps
}: FlagPickerButtonProps) {
  const { status, data } = useQuery({
    enabled: featureFlagKey !== undefined && featureFlagKey !== '',
    ...getFeatureFlagQuery({
      projectKey,
      featureFlagKey: nullthrows(featureFlagKey),
      query: {
        env: [environmentKey],
      },
    }),
  });

  if (featureFlagKey === undefined || featureFlagKey === '') {
    return (
      <Button {...buttonProps}>
        <div className={styles.Flag}>Select flag</div>
        <Icon name="chevron-down" />
      </Button>
    );
  }

  if (status === 'pending') {
    return (
      <Button {...buttonProps}>
        <div className={styles.Flag}>
          {includeStatus && (
            <FlagStatus projectKey={projectKey} featureFlagKey={featureFlagKey} environmentKey={environmentKey} />
          )}
          <div className={styles.Name}>{featureFlagKey}</div>
        </div>
        <Icon name="chevron-down" />
      </Button>
    );
  }

  if (status === 'error') {
    return (
      <Button {...buttonProps}>
        <div className={styles.Flag}>
          <div className={styles.Name}>{featureFlagKey}</div>
        </div>
        <Icon name="chevron-down" />
      </Button>
    );
  }

  return (
    <Button {...buttonProps}>
      <div className={styles.Flag}>
        {includeStatus && (
          <FlagStatus projectKey={projectKey} featureFlagKey={data.key} environmentKey={environmentKey} />
        )}
        <div className={styles.Name}>{data.name}</div>
        {includeTargeting && (
          <TargetingTag isOn={isFlagOnForEnvironment(getFlagConfigurationForEnvironment(data, environmentKey))} />
        )}
      </div>
      <Icon name="chevron-down" />
    </Button>
  );
}
