import { SeriesListRep } from '@gonfalon/rest-api';
import invariant from 'tiny-invariant';

import { findVariationByValue } from './findVariationByValue';
import { type Variation } from './types';

export function tallyActiveVariations(variations: Variation[], series: SeriesListRep) {
  const variationIdsByMetadataIndex: { [index: number]: string } = {};
  for (const [index, meta] of series.metadata.entries()) {
    const variation = findVariationByValue(meta.key, variations);
    if (variation) {
      // @ts-expect-error variation._id should not be optional (legacy openapi type)
      variationIdsByMetadataIndex[index] = variation._id;
    }
  }

  const layers: { [id: string]: number[] } = {};
  for (const [variationIndex, id] of Object.entries(variationIdsByMetadataIndex)) {
    layers[id] = layers[id] ?? [];
    for (const point of series.series) {
      layers[id].push(point[variationIndex] ?? 0);
    }
  }

  const totalsByVariationId: { [id: string]: number } = {};
  for (const [, id] of Object.entries(variationIdsByMetadataIndex)) {
    totalsByVariationId[id] = totalsByVariationId[id] ?? 0;
    for (const count of layers[id]) {
      totalsByVariationId[id] += count;
    }
  }

  // Don't hide variations with 0 events -- seems confusing now.
  let activeVariations = Array.from(variations);
  activeVariations = activeVariations.sort((a, b) => {
    invariant(a._id, 'Expected variation to have an id');
    invariant(b._id, 'Expected variation to have an id');
    return (totalsByVariationId[a._id] || 0) > (totalsByVariationId[b._id] || 0) ? -1 : 0;
  });

  return {
    sum: series.totalEvaluations ?? 0,
    totalsByVariationId,
    activeVariations,
  };
}
