import { getFlagContextTargetVariationIndices } from './getFlagContextTargetVariationIndices';
import { getFlagFallthroughRolloutVariationIndices } from './getFlagFallthroughRolloutVariationIndices';
import { getFlagRuleVariationIndices } from './getFlagRuleVariationIndices';
import { getFlagTargetVariationIndices } from './getFlagTargetVariationIndices';
import { getFlagVariationIndexFromId } from './getFlagVariationIndexFromId';
import { isFlagOnForEnvironment } from './isFlagOnForEnvironment';
import { FeatureFlag, FeatureFlagConfig, Variation } from './types';

export function getFlagActiveVariationsFromConfig(config: FeatureFlagConfig, variations: FeatureFlag['variations']) {
  const activeVariations: Array<[variationIndex: number, variation: Variation]> = [];

  if (!isFlagOnForEnvironment(config)) {
    if (config.offVariation === undefined) {
      return activeVariations;
    }

    const offVariation = variations[config.offVariation];
    if (offVariation === undefined) {
      return activeVariations;
    }

    const variationIndex = getFlagVariationIndexFromId(variations, offVariation._id ?? '');
    if (variationIndex === -1) {
      return activeVariations;
    }

    activeVariations.push([variationIndex, offVariation]);

    return activeVariations;
  }

  // This logic also lives on the backend in internal/reps2/flag_rep2.go#MakeConfigurationRep.
  // If you add or remove variation types here, you may also need to update on the backend.
  const activeVariationIndexes = Array.from(
    new Set([
      ...getFlagRuleVariationIndices(config),
      ...getFlagContextTargetVariationIndices(config),
      ...getFlagTargetVariationIndices(config),
      ...getFlagFallthroughRolloutVariationIndices(config),
    ]),
  );

  if (config.fallthrough?.variation !== undefined && !activeVariationIndexes.includes(config.fallthrough?.variation)) {
    activeVariationIndexes.push(config.fallthrough.variation);
  }

  // then turn it into a map with their full-fledged variation objects
  activeVariationIndexes.forEach((i) => {
    const variation = variations[Number(i)];
    if (variation) {
      activeVariations.push([i, variation]);
    }
  });

  return activeVariations;
}
