import { stringifyValue, StringifyValueOptions } from '@gonfalon/types';

export type GetFlagVariationValueStringOptions = {
  displayValueForEmptyStrings?: boolean;
  allowScientificNotation?: boolean;
};

export function getFlagVariationValueString(val: unknown, options?: StringifyValueOptions) {
  return stringifyValue(val, options);
}
