import { getFlagVariationValueString, GetFlagVariationValueStringOptions } from './getFlagVariationValueString';
import { Variation } from './types';

export function getFlagVariationDisplayValue(variation: Variation, options?: GetFlagVariationValueStringOptions) {
  if (!!variation.name) {
    return variation.name;
  }

  return getFlagVariationValueString(variation.value, options);
}
