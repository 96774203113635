import { internalEnvironmentSchema } from '@gonfalon/environments';
import { schemas } from '@gonfalon/openapi';
import { projectSchema } from '@gonfalon/projects';
import { z } from 'zod';

import { type ProjectContext, projectContextSchema } from './projectContext';

// react-router-dom loaders aren't typed yet, so we validate their data ourselves for now.
// This schema corresponds to the route with the "project-context" id.
export const projectDataSchema = z.object({
  context: projectContextSchema,
  project: projectSchema,
  environments: z.array(internalEnvironmentSchema),
});

export type Member = schemas['Member'];
export type Account = schemas['Account'];

export type EnvironmentAware<T extends object> = T & {
  selectedEnvironmentKey?: ProjectContext['selectedEnvironmentKey'] | undefined;
};

export type ProjectContextSearchParams = Pick<ProjectContext, 'environmentKeys' | 'selectedEnvironmentKey'>;
