import { environmentSchema } from '@gonfalon/environments';
import { z } from 'zod';

export const projectSchema = z.object({
  _id: z.string(),
  _links: z.record(z.any()),
  defaultClientSideAvailability: z.object({
    usingEnvironmentId: z.boolean(),
    usingMobileKey: z.boolean(),
  }),
  defaultReleasePipelineKey: z.string().optional(),
  environments: z.object({ items: z.array(environmentSchema) }).optional(),
  includeInSnippetByDefault: z.boolean(),
  key: z.string(),
  name: z.string(),
  tags: z.array(z.string()),
});
