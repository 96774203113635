export const SEGMENT_MATCH_OP = 'segmentMatch';
export const APPLICATION_VERSION_SUPPORTED_OP = 'applicationVersionSupported';
export const DATE_BEFORE_OP = 'before';
export const DATE_AFTER_OP = 'after';
export const APP_VERSION_SUPPORT_STATUS_ATTRIBUTE = 'version_support_status';
export const OS_VERSION_ATTRIBUTE = '/os/version';

export const segmentAttributeOptions = [
  { value: 'segmentMatch', label: 'is in segment' },
  { value: 'not-segmentMatch', label: 'is not in segment' },
];

export const specialApplicationAttributeOptions = [
  {
    value: 'app_support_status',
    label: 'App support status',
  },
];

export const segmentAttributeReadableValue = {
  segmentMatch: 'is in segment',
  'not-segmentMatch': 'is not in segment',
};

export const booleanOptions = [
  { value: true, label: 'true' },
  { value: false, label: 'false' },
];

export const semVerOperatorOptions = [
  {
    value: 'semVerEqual',
    label: 'SemVer is one of',
    op: 'semVerEqual',
    negate: false,
  },
  {
    value: 'not-semVerEqual',
    label: 'SemVer is not one of',
    op: 'semVerEqual',
    negate: true,
  },
  {
    value: 'semVerLessThan',
    label: 'SemVer <',
    op: 'semVerLessThan',
    negate: false,
  },
  {
    value: 'not-semVerGreaterThan',
    label: 'SemVer <=',
    op: 'semVerGreaterThan',
    negate: true,
  },
  {
    value: 'semVerGreaterThan',
    label: 'SemVer >',
    op: 'semVerGreaterThan',
    negate: false,
  },
  {
    value: 'not-semVerLessThan',
    label: 'SemVer >=',
    op: 'semVerLessThan',
    negate: true,
  },
];

export const operatorOptions = [
  { value: 'in', label: 'is one of', op: 'in', negate: false },
  { value: 'not-in', label: 'is not one of', op: 'in', negate: true },
  { value: 'endsWith', label: 'ends with', op: 'endsWith', negate: false },
  {
    value: 'not-endsWith',
    label: 'does not end with',
    op: 'endsWith',
    negate: true,
  },
  {
    value: 'startsWith',
    label: 'starts with',
    op: 'startsWith',
    negate: false,
  },
  {
    value: 'not-startsWith',
    label: 'does not start with',
    op: 'startsWith',
    negate: true,
  },
  { value: 'matches', label: 'matches regex', op: 'matches', negate: false },
  {
    value: 'not-matches',
    label: 'does not match regex',
    op: 'matches',
    negate: true,
  },
  { value: 'contains', label: 'contains', op: 'contains', negate: false },
  {
    value: 'not-contains',
    label: 'does not contain',
    op: 'contains',
    negate: true,
  },
];

export const comparisonOperatorOptions = [
  { value: 'lessThan', label: '<', op: 'lessThan', negate: false },
  {
    value: 'lessThanOrEqual',
    label: '<=',
    op: 'lessThanOrEqual',
    negate: false,
  },
  { value: 'greaterThan', label: '>', op: 'greaterThan', negate: false },
  {
    value: 'greaterThanOrEqual',
    label: '>=',
    op: 'greaterThanOrEqual',
    negate: false,
  },
];

export const dateOperatorOptions = [
  { value: 'before', label: 'before', op: 'before', negate: false },
  { value: 'after', label: 'after', op: 'after', negate: false },
];

export const applicationSupportedOptions = [
  {
    value: 'applicationVersionSupported',
    label: 'is supported',
    op: 'applicationVersionSupported',
    negate: false,
  },
  {
    value: 'not-applicationVersionSupported',
    label: 'is unsupported',
    op: 'applicationVersionSupported',
    negate: true,
  },
];

export const clauseOpOptions = [
  ...operatorOptions,
  ...comparisonOperatorOptions,
  ...dateOperatorOptions,
  ...semVerOperatorOptions,
];

export const comparisonOps = ['lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'] as const;
export const stringOps = ['startsWith', 'endsWith', 'containers'] as const;
