import { UIContextsKindsView } from './types';

export function parseContextKindsSearchParamsFromBrowser(searchParams: URLSearchParams) {
  const filters: UIContextsKindsView = {};
  if (searchParams.has('offset')) {
    filters.offset = Number(searchParams.get('offset'));
  }

  if (searchParams.has('query')) {
    filters.query = searchParams.get('query') || undefined;
  }

  return filters;
}
