import { getRuleFromIndex } from './getRuleFromIndex';
import { getRuleIndexFromId } from './getRuleIndexFromId';
import { FeatureFlag } from './types';

export function getRuleDisplayValue(flag: FeatureFlag, environmentKey: string, ruleId: string) {
  if (ruleId === 'fallthrough') {
    return 'Default rule';
  }

  const ruleIndex = getRuleIndexFromId(flag, environmentKey, ruleId);

  if (ruleIndex === -1) {
    return '(Deleted rule)';
  }

  const rule = getRuleFromIndex(flag, environmentKey, ruleIndex);
  return rule.description ?? `Rule ${ruleIndex + 1}`;
}
