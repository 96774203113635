import { Action, AnyAction, Dispatch } from 'redux';
import { combineEpics, createEpicMiddleware, Epic, EpicMiddleware } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { GlobalState } from 'reducers';

type RegistryStatus = 'idle' | 'running';

// largely based on the code-splitting example from the official documentation:
// https://redux-observable.js.org/docs/recipes/AddingNewEpicsAsynchronously.html
export class EpicRegistry<S extends {} = GlobalState, T extends Action = AnyAction> {
  private epics: { [epicKey: string]: Epic<T, T, S> };
  private readonly epic$: BehaviorSubject<Epic<T, T, S>>;
  private readonly middleware: EpicMiddleware<T, T, S, Dispatch<T>>;
  private readonly rootEpic: Epic<T, T, S>;
  private status: RegistryStatus;

  constructor() {
    this.epics = {};
    this.epic$ = new BehaviorSubject(combineEpics());

    // A redux-observable epic is essentially an Observable<Action>. You can think of Observable
    // as a Promise which may resolve to multiple values across time.
    // So, an Observable<Action> is kind of like a Promise that may resolve to different actions
    // over time. And since redux-observable is a Redux middleware, you can think of an epic as
    // a function that receives any dispatched action, and which emits actions over time.
    //
    // The epic registry is essentially a mega epic: it starts off empty, and as epics register
    // themselves, as it receives new dispatched actions, it hands those off to each of the registered
    // epics. (Similar to combineReducers, right?)
    //
    // Let's look at the (pseudo) types to understand what is going here:
    //
    // const epic$: Array<Observable<Action>>;
    // const rootEpic: (a: Action) => Observable<Action>;
    //
    // Whenever an action is dispatched, we want to send it to each item in epic$. Let's (pseudo) implement
    // rootEpic:
    //
    // const rootEpic: (a: Action) => Observable<Action) => epic$.map(
    //   (epic: (a: Action) => Observable<Action>) => epic(action))
    // );
    //
    // However, there's a type error there: because of map(), rootEpic is actually of type,
    //
    // (a: Action) => Array<Observable<Action>>
    //
    // To fix that, we use mergeMap(): it maps and then "flattens" or combines the values of each observable
    // item into a single Observable<Action>.
    this.rootEpic = (action$, state$) => this.epic$.pipe(mergeMap((epic) => epic(action$, state$, undefined)));

    this.middleware = createEpicMiddleware();

    this.status = 'idle';
  }

  getMiddleware() {
    return this.middleware;
  }

  run() {
    if (this.status === 'running') {
      return;
    }

    this.status = 'running';
    this.middleware.run(this.rootEpic);

    // Some epics loaded before the store was created, and before the registry had a chance to run,
    // which means we should register them now.
    Object.values(this.epics).forEach((epic) => this.epic$.next(epic));
  }

  addEpics(epics: Array<Epic<T, T, S>>) {
    epics.forEach((e) => {
      const epicKey = e.toString();
      // Check if epic already exists
      if (!this.epics.hasOwnProperty(epicKey)) {
        this.epics[epicKey] = e;

        if (this.status === 'running') {
          this.epic$.next(e);
        }
      }
    });
  }
}

const epicRegistry = new EpicRegistry();
/* eslint-disable import/no-default-export */
export default epicRegistry;
