import { useEnvironment } from '@gonfalon/rest-api';

import { StandaloneEnvironmentDisplay } from './StandaloneEnvironmentDisplay';

interface Props {
  projectKey: string;
  environmentKey: string;
}

export function EnvironmentDisplay({ projectKey, environmentKey }: Props) {
  const { data: environment } = useEnvironment({ projectKey, environmentKey });

  if (!environment) {
    return null;
  }

  return <StandaloneEnvironmentDisplay color={environment.color} name={environment.name} />;
}
