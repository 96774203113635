import { DialogTrigger, Popover } from '@launchpad-ui/components';

import { FlagPickerButton } from './FlagPickerButton';
import { FlagPickerDialog } from './FlagPickerDialog';
import { FlagPickerProps } from './types';

/**
 * Pick a flag from a list of flags.
 *
 * Note that an environment is required, so the picker can display the current status and targeting state
 * of the flag in that given environment.
 */
export function FlagPicker({
  projectKey,
  selectedFlagKey: featureFlagKey,
  environmentKey,
  onSelectionChange,
  includeStatus = false,
  includeTargeting = false,
  ...buttonProps
}: FlagPickerProps) {
  return (
    <DialogTrigger>
      <FlagPickerButton
        {...buttonProps}
        projectKey={projectKey}
        selectedFlagKey={featureFlagKey}
        environmentKey={environmentKey}
        includeStatus={includeStatus}
        includeTargeting={includeTargeting}
      />
      <Popover placement="bottom start">
        <FlagPickerDialog
          projectKey={projectKey}
          environmentKey={environmentKey}
          onSelectionChange={onSelectionChange}
          includeStatus={includeStatus}
          includeTargeting={includeTargeting}
        />
      </Popover>
    </DialogTrigger>
  );
}
