import { Member } from '@gonfalon/members';
import { checkAccess } from '@gonfalon/permissions';

import { Project } from './types';

// Document project actions here.
// Ideally this would come from OpenAPI, but
// our docs aren't structured that way yet.
export type Action =
  | 'createReleasePipeline'
  | 'updateReleasePipeline'
  | 'deleteReleasePipeline'
  | 'updateProjectName'
  | 'updateTags'
  | 'deleteProject';

export function checkProjectAccess(project: Project, member: Member, action: Action) {
  const access = project._access;
  return checkAccess(member, access, action);
}
