import {
  APP_VERSION_SUPPORT_STATUS_ATTRIBUTE,
  applicationSupportedOptions,
  clauseOpOptions,
  comparisonOperatorOptions,
  operatorOptions,
  OS_VERSION_ATTRIBUTE,
  semVerOperatorOptions,
} from './constants';
import { isMobileClause } from './isMobileClause';
import { Clause } from './types';

export function getClauseOpOptionsForClause(clause: Clause) {
  if (isMobileClause(clause)) {
    switch (clause.attribute) {
      case APP_VERSION_SUPPORT_STATUS_ATTRIBUTE:
        return [...applicationSupportedOptions];
      case 'version':
      case 'versionName':
      case OS_VERSION_ATTRIBUTE:
        return [...semVerOperatorOptions, ...comparisonOperatorOptions, ...operatorOptions];
      default:
        return clauseOpOptions;
    }
  }

  return clauseOpOptions;
}
