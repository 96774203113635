import { and, anyOf, contains, equals, or, QueryFilter, queryfilterToString, startsWith } from '@gonfalon/queryfilter';

export function buildContextFilter(textFilter: string | undefined, unhiddenContextKinds: Array<{ key: string }>) {
  const matches: QueryFilter[] = [];

  const unhiddenContextsFilter = anyOf(
    'kind',
    unhiddenContextKinds.map((k) => k.key),
  );

  // If there's no text filter, just return the unhidden contexts filter by default
  if (!textFilter) {
    return queryfilterToString(and([unhiddenContextsFilter]));
  }

  const isKindKey = textFilter.includes(':');

  matches.push(
    or([
      startsWith('*.name', textFilter),
      startsWith('key', textFilter),
      startsWith('user.lastName', textFilter),
      startsWith('user.firstName', textFilter),
      startsWith('*.key', textFilter),
      isKindKey ? contains('kindKeys', [textFilter]) : equals('q', textFilter),
    ]),
  );

  matches.push(unhiddenContextsFilter);

  return queryfilterToString(and(matches));
}
