/**
 * This is a very simple placeholder type for an Immutable.js object, which here we are only checking by if the value is an object and has a `toJS()` method.
 */
export type ImmutableJSObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJS(): any;
};

/**
 * This does a basic check to see if a value is an Immutable.js structure by if it includes a `.toJS()` method.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isImmutable(val: unknown): val is ImmutableJSObject {
  return val !== null && typeof val === 'object' && 'toJS' in val && typeof val.toJS === 'function';
}
