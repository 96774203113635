import invariant from 'tiny-invariant';

import { findVariationIndexByValue } from './findVariationIndexByValue';
import { Variation } from './types';

export function findVariationByValue(value: unknown, variations: Variation[]) {
  const index = findVariationIndexByValue(value, variations);

  if (index === -1) {
    return undefined;
  }

  const variation = variations.at(index);
  invariant(variation);

  return variation;
}
