import { Diamond } from '@gonfalon/icons';
import type { IconProps } from '@launchpad-ui/icons';

import { getVariationFillColor } from '../getVariationFillColor';

export type VariationColorProps = {
  variationIndex: number;
  className?: string;
  size?: IconProps['size'];
};

export function VariationColor({ variationIndex, className, size = 'small' }: VariationColorProps) {
  return <Diamond className={className} size={size} fill={getVariationFillColor(variationIndex)} />;
}
