import { createMissingEnvironmentError } from './errors';
import { FeatureFlag } from './types';

export function getFlagConfigurationForEnvironment(flag: FeatureFlag, environmentKey: string) {
  const config = flag.environments?.[environmentKey];
  if (!config) {
    throw createMissingEnvironmentError(environmentKey);
  }

  return config;
}
