import { ContextKind } from '@gonfalon/context-kinds';

import { Clause } from './types';

export function formatClauseContextKind(clause: Clause) {
  const { contextKind } = clause;
  switch (contextKind) {
    case ContextKind.DEVICE:
      return 'Device';
    case ContextKind.APPLICATION:
      return 'Application';
    default:
      return contextKind || ContextKind.USER;
  }
}
