import { VariationDisplay } from '@gonfalon/flags';
import { Button, DialogTrigger, Popover } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

import { VariationPickerProps } from './types';
import { VariationPickerDialog } from './VariationPickerDialog';

import styles from './VariationPicker.module.css';

/**
 * Pick a variation from a list of variations.
 */
export function VariationPicker({
  selectedVariationIndex,
  variations,
  onSelectionChange,
  ...buttonProps
}: VariationPickerProps) {
  const selectedVariation = selectedVariationIndex !== undefined ? variations[selectedVariationIndex] : undefined;

  return (
    <DialogTrigger>
      <Button {...buttonProps}>
        <div className={styles.Variation}>
          {selectedVariationIndex !== undefined && selectedVariation ? (
            <VariationDisplay variation={selectedVariation} variationIndex={selectedVariationIndex} />
          ) : (
            'Select variation'
          )}
        </div>
        <Icon name="chevron-down" />
      </Button>
      <Popover placement="bottom start">
        <VariationPickerDialog variations={variations} onSelectionChange={onSelectionChange} />
      </Popover>
    </DialogTrigger>
  );
}
