import { getFlagConfigurationForEnvironment } from './getFlagConfigurationForEnvironment';
import { FeatureFlag, FeatureFlagStatus } from './types';

// original implementation 'getRolloutValue' from 'ld/utils/archiveUtils.ts'

export function getFlagVariationForEnvironment(
  flag: FeatureFlag,
  environmentKey: string,
  flagStatus: FeatureFlagStatus,
) {
  const config = getFlagConfigurationForEnvironment(flag, environmentKey);

  if (config.on) {
    const fallthroughVariationIndex = config.fallthrough?.variation;
    if (fallthroughVariationIndex !== undefined) {
      return {
        variation: flag.variations[fallthroughVariationIndex],
        variationIndex: fallthroughVariationIndex,
      };
    }
  }

  const offVariationIndex = config.offVariation;
  if (offVariationIndex !== undefined && offVariationIndex >= 0) {
    return { variation: flag.variations[offVariationIndex], variationIndex: offVariationIndex };
  }

  if (flagStatus.default) {
    const variationValues = flag.variations.map((v) => v.value);
    const variationIndex = variationValues.indexOf(flagStatus.default);
    return { variation: flag.variations[variationIndex], variationIndex };
  }

  return { variation: undefined, variationIndex: undefined };
}
