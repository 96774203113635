import { isSegmentOp } from './isSegmentOp';
import { Clause } from './types';

export function getAttributeFromClause(clause: Clause) {
  if (isSegmentOp(clause.op)) {
    return clause.negate ? 'not-segmentMatch' : 'segmentMatch';
  }

  return clause.attribute;
}
