import { isComparisonOp } from './isComparisonOp';
import { isMatchesOp } from './isMatchesOp';
import { isSemVerOp } from './isSemVerOp';
import { isStringOp } from './isStringOp';
import { Clause } from './types';

export function getClauseExpectedValueType(clause: Clause) {
  if (isStringOp(clause.op) || isSemVerOp(clause.op) || isMatchesOp(clause.op)) {
    return 'string';
  } else if (isComparisonOp(clause.op)) {
    return 'number';
  }
}
