import { type ReactNode, useEffect } from 'react';
import { useProjectContext } from '@gonfalon/context';
import { ErrorBoundary as ErrorBoundary_ } from '@gonfalon/error-boundaries';
import { isFlagError } from '@gonfalon/flags';
import { isRESTAPIError } from '@gonfalon/rest-api';
import { Button } from '@launchpad-ui/components';
import { Alert } from 'launchpad';
import nullthrows from 'nullthrows';

import { useCurrentEnvironmentKey } from 'reducers/projects';

export function SyncReduxWithProjectContext({ children }: { children: ReactNode }) {
  const context = useProjectContext();
  const legacyCurrentEnvironmentKey = useCurrentEnvironmentKey();

  useEffect(() => {
    const {
      context: { projectKey, selectedEnvironmentKey },
      environments,
      project: project_,
    } = context;

    const currentEnvironment = environments.find((env) => env.key === selectedEnvironmentKey);
    window.__resetStore({
      condition: ({ currentProjectKey, currentEnvironmentKey }) =>
        currentProjectKey !== projectKey || currentEnvironmentKey !== selectedEnvironmentKey,
      payload: {
        initialState: {
          currentProject: project_,
          currentEnvironment: nullthrows(currentEnvironment, 'Expected current environment'),
        },
      },
    });
  }, [context]);

  if (legacyCurrentEnvironmentKey !== context.context.selectedEnvironmentKey) {
    return null;
  }

  return <ErrorBoundary>{children}</ErrorBoundary>;
}

function ErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary_ fallbackRender={Fallback} severity="high" ignoredStatuses={[404]}>
      {children}
    </ErrorBoundary_>
  );
}

function Fallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  if (isFlagError(error)) {
    switch (error.code) {
      case 'missing_environment':
        return (
          <Alert isInline size="small" kind="error">
            {error.message}
            <Button size="small" onPress={() => resetErrorBoundary()}>
              Try again
            </Button>
          </Alert>
        );
      default:
        return <div>Something went wrong</div>;
    }
  }
  if (isRESTAPIError(error) && error.status === 404) {
    return <div>Flag not found. Please refresh the page.</div>;
  }
  return <div>Something went wrong</div>;
}
