import { queryfilterFromString } from '@gonfalon/queryfilter';
import { PostContextsParams } from '@gonfalon/rest-api';

import { UIContextsView } from './types';

export function parseUIContextViewFromAPI(api: PostContextsParams) {
  const ui: UIContextsView = {};
  if (api.filter) {
    const parse = queryfilterFromString(api.filter);
    if (parse.ok) {
      ui.filter = parse.val;
    }
  }
  return ui;
}
