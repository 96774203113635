import { ContextKind } from '@gonfalon/context-kinds';

import { isSegmentClause } from './isSegmentClause';
import { Clause } from './types';

export function getClauseKind(clause: Clause) {
  if (clause.contextKind === ContextKind.APPLICATION) {
    return 'application';
  } else if (clause.contextKind === ContextKind.DEVICE) {
    return 'device';
  } else if (isSegmentClause(clause)) {
    return 'segment';
  } else {
    return 'custom';
  }
}
