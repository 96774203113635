import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router';
import { useProjectContext } from '@gonfalon/context';
import {
  enableOpenSeatsSnackbarNotification,
  isCommandBarEnabled,
  isSkipToContentEnabled,
  isVideoInAppEnabled,
} from '@gonfalon/dogfood-flags';
import { NoisyErrorBoundary } from '@gonfalon/error-boundaries';
import { SnackbarRegion, ToastRegion } from '@launchpad-ui/components';

import SkipToContent from './SkipToContent/SkipToContent';
import Announcer from './Announcer';
import { SyncReduxWithProjectContext } from './SyncReduxWithProjectContext';

const CommandBarLoader = lazy(async () =>
  import(/* webpackChunkName: "CommandBarLoader" */ 'command-bar/CommandBarLoader').then((module) => ({
    default: module.CommandBarLoader,
  })),
);

const OpenSeatNotification = lazy(
  async () =>
    import(/* webpackChunkName: "OpenSeatNotification" */ 'components/openSeatNotification/OpenSeatNotification'),
);

const OneTimeNotificationModals = lazy(async () =>
  import(
    /* webpackChunkName: "OneTimeNotificationModals" */ 'components/oneTimeNotificationModals/OneTimeNotificationModals'
  ).then((module) => ({
    default: module.OneTimeNotificationModals,
  })),
);

const LazyLearningCenterContainer = lazy(
  async () => import(/* webpackChunkName: "LazyLearningCenterContainer" */ 'components/LazyLearningCenterContainer'),
);

const LearningCenterGetStartedVideoModal = lazy(async () =>
  import(
    /* webpackChunkName: "GetStartedVideoModal" */ 'components/learningCenterDrawers/getStartedDrawer/GetStartedVideoModal'
  ).then((module) => ({ default: module.GetStartedVideoModal })),
);

const InviteMembersModalContainer = lazy(async () =>
  import(
    /* webpackChunkName: "InviteMembersModalContainer" */ 'components/inviteMembers/InviteMembersModalContainer'
  ).then((module) => ({
    default: module.InviteMembersModalContainer,
  })),
);

const SuggestInviteMembersModalContainer = lazy(async () =>
  import(
    /* webpackChunkName: "SuggestInviteMembersModalContainer" */ 'components/inviteMembers/SuggestInviteMembersModalContainer'
  ).then((module) => ({
    default: module.SuggestInviteMembersModalContainer,
  })),
);

const NotificationCenterContainer = lazy(
  async () => import(/* webpackChunkName: "NotificationCenterContainer" */ 'components/NotificationCenterContainer'),
);

export type AppShellProps = {};

/**
 * AppShell owns shared top-level components that are part of our app. No matter where
 * the user is, we should be able to render these above everything else.
 */
export function AppShell() {
  const isInProjectContext = useProjectContext({ optional: true });

  return (
    <div>
      {isSkipToContentEnabled() && <SkipToContent />}
      <Announcer />

      <NoisyErrorBoundary severity="critical">
        <Suspense fallback={null}>
          <OneTimeNotificationModals />
        </Suspense>
        <Suspense fallback={null}>
          <LazyLearningCenterContainer />
        </Suspense>
        {isVideoInAppEnabled() && (
          <Suspense fallback={null}>
            <LearningCenterGetStartedVideoModal />
          </Suspense>
        )}

        <Suspense fallback={null}>
          <InviteMembersModalContainer />
        </Suspense>
        <Suspense fallback={null}>
          <SuggestInviteMembersModalContainer />
        </Suspense>

        {isCommandBarEnabled() && (
          <Suspense fallback={null}>
            {isInProjectContext ? (
              <SyncReduxWithProjectContext>
                <CommandBarLoader preferredFormFactor="modal" />
              </SyncReduxWithProjectContext>
            ) : (
              <CommandBarLoader preferredFormFactor="modal" disableSpotlightKeyboardCommand />
            )}
          </Suspense>
        )}

        {enableOpenSeatsSnackbarNotification() && (
          <Suspense fallback={null}>
            <OpenSeatNotification />
          </Suspense>
        )}

        <Suspense fallback={null}>
          <NotificationCenterContainer />
        </Suspense>

        <SnackbarRegion />
        <ToastRegion />
      </NoisyErrorBoundary>

      <Outlet />
    </div>
  );
}
