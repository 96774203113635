import clsx from 'clsx';

import { EnvironmentMarker } from '../EnvironmentMarker';

import styles from './EnvironmentDisplay.module.css';

interface Props {
  color: string;
  name: string;
  className?: string;
}

export function StandaloneEnvironmentDisplay({ color, name, className }: Props) {
  return (
    <div className={clsx(styles.environment, className)}>
      <EnvironmentMarker color={color} className={styles.color} />
      <span className={styles.display}>{name}</span>
    </div>
  );
}
