import { isAnyRuleSendingEvents } from './isAnyRuleSendingEvents';

export function isTrackingAnyEvents(
  trackEvents: boolean,
  hasExperiments: boolean,
  flagConfig: {
    trackEventsFallthrough?: boolean;
    rules?: Array<{ trackEvents?: boolean }>;
  },
) {
  return trackEvents || (hasExperiments && !isAnyRuleSendingEvents(flagConfig));
}
