import { UIContextsKindsView } from './types';

export function seralizeContextKindsSearchParamsToBrowser(filters: UIContextsKindsView) {
  const params = new URLSearchParams();
  if (filters.offset) {
    params.set('offset', filters.offset.toString());
  }
  if (filters.query) {
    params.set('query', filters.query);
  }

  return params;
}
