import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, Route } from 'react-router';
import { useMatchingAppPathPattern } from '@gonfalon/router';
import { setRouteContext } from '@gonfalon/telemetry';

import { AppShell } from 'components/AppShell';

import { appRouteOwnership } from './appRouteOwnership';

const ApplicationsListContainer = async () =>
  import(/* webpackChunkName: "applications.list" */ 'routes/applications/index');

export const appRoutes = () => (
  <Route element={<OwnershipWatcher />}>
    {/* renders fullpage json and therefore needs to be outside the appshell */}
    <Route
      path="/projects/:projectKey/errors/:issueId/json"
      lazy={async () =>
        import(/* webpackChunkName: "ErrorJson" */ 'routes/app/projects.$projectKey.errors.$issueId.json')
      }
    />
    <Route element={<AppShell />}>
      <Route path="/" lazy={async () => import(/* webpackChunkName: "Root" */ 'routes/app/root')}>
        <Route
          path="confirm-auth/:deviceCode"
          lazy={async () => import(/* webpackChunkName: "ConfirmAuth" */ 'routes/app/confirm-auth.$deviceCode')}
        />
        <Route
          path="insights"
          lazy={async () => import(/* webpackChunkName: "launchInsights.entry" */ 'routes/app/insights')}
        />

        <Route
          path="product-analytics/integrations/snowflake"
          lazy={async () =>
            import(
              /* webpackChunkName: "snowflakeProductAnalyticsIntegration" */ 'routes/app/snowflake-product-analytics-integration'
            )
          }
        />

        <Route
          id="project-context"
          path="/projects/:projectKey"
          lazy={async () => import(/* webpackChunkName: "ProjectSection"*/ 'routes/app/projects.$projectKey')}
        >
          <Route lazy={async () => import(/* webpackChunkName: "ProjectLayout" */ 'routes/app/projects.$projectKey_')}>
            <Route
              path="product-analytics"
              lazy={async () =>
                import(/* webpackChunkName: "ProjectLayout" */ 'routes/app/projects.$projectKey.product-analytics_')
              }
            >
              <Route
                index
                lazy={async () =>
                  import(
                    /* webpackChunkName: "product-analytics" */ 'routes/app/projects.$projectKey.product-analytics'
                  )
                }
              />
              <Route
                path="visualizations/:id"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "product-analytics-visualizations-new" */ 'routes/app/projects.$projectKey.product-analytics.visualizations.$id'
                  )
                }
              />
              <Route path="events">
                <Route
                  index
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "product-analytics-events" */ 'routes/app/projects.$projectKey.product-analytics.events'
                    )
                  }
                />
                <Route
                  path="details/:id/edit"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "product-analytics-event-edit" */ 'routes/app/projects.$projectKey.product-analytics.events.details.$id.edit'
                    )
                  }
                />
                <Route
                  path="new"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "product-analytics-event-new" */ 'routes/app/projects.$projectKey.product-analytics.events.new'
                    )
                  }
                />
              </Route>
              <Route path="cohorts">
                <Route
                  index
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "product-analytics-cohorts" */ 'routes/app/projects.$projectKey.product-analytics.cohorts'
                    )
                  }
                />
                <Route
                  path=":id"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "product-analytics-cohort-details" */ 'routes/app/projects.$projectKey.product-analytics.cohorts.$id'
                    )
                  }
                />
                <Route
                  path=":id/edit"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "product-analytics-cohort-edit" */ 'routes/app/projects.$projectKey.product-analytics.cohorts.$id.edit'
                    )
                  }
                />
                <Route
                  path="new"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "product-analytics-cohort-new" */ 'routes/app/projects.$projectKey.product-analytics.cohorts.new'
                    )
                  }
                />
              </Route>
              <Route
                path="user-attributes"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "product-analytics-user-attributes" */ 'routes/app/projects.$projectKey.product-analytics.user-attributes'
                  )
                }
              />
              <Route
                path="user-activity"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "product-analytics-user-activity" */ 'routes/app/projects.$projectKey.product-analytics.user-activity'
                  )
                }
              />
              <Route path="dashboards">
                <Route
                  index
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "product-analytics-dashboards" */ 'routes/app/projects.$projectKey.product-analytics.dashboards'
                    )
                  }
                />
                <Route
                  path=":id"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "product-analytics-dashboard-details" */ 'routes/app/projects.$projectKey.product-analytics.dashboards.$id'
                    )
                  }
                >
                  <Route
                    path=":nestedDashboardId"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "product-analytics-dashboard-nested" */ 'routes/app/projects.$projectKey.product-analytics.dashboards.$id.$nestedDashboardId'
                      )
                    }
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route
            path="audit"
            lazy={async () =>
              import(/* webpackChunkName: "AuditLogComponent" */ 'routes/app/projects.$projectKey.audit')
            }
          />
          <Route
            path="audit/:auditId/*"
            lazy={async () =>
              import(/* webpackChunkName: "AuditLogEntryComponent" */ 'routes/app/projects.$projectKey.audit.$auditId')
            }
          />
          <Route path="get-started">
            <Route
              index
              lazy={async () =>
                import(/* webpackChunkName: "GetStarted" */ 'routes/app/projects.$projectKey.get-started')
              }
            />
            <Route
              path="quickstart"
              lazy={async () =>
                import(/* webpackChunkName: "GetStarted" */ 'routes/app/projects.$projectKey.get-started')
              }
            />
            <Route
              path="setup-cli"
              lazy={async () =>
                import(/* webpackChunkName: "GetStarted" */ 'routes/app/projects.$projectKey.get-started')
              }
            />
            <Route
              path="invite-members"
              lazy={async () =>
                import(/* webpackChunkName: "GetStarted" */ 'routes/app/projects.$projectKey.get-started')
              }
            />
          </Route>
          <Route lazy={async () => import(/* webpackChunkName: "ProjectLayout" */ 'routes/app/projects.$projectKey_')}>
            <Route index element={<Navigate to="flags" replace />} />
            <Route
              path="guarded-rollouts"
              lazy={async () =>
                import(/* webpackChunkName: "GuardedRollouts" */ 'routes/app/projects.$projectKey.guarded-rollouts')
              }
            ></Route>

            <Route
              path="flags"
              lazy={async () => import(/* webpackChunkName: "Flags" */ 'routes/app/projects.$projectKey.flags_')}
            >
              <Route
                path="new"
                lazy={async () =>
                  import(/* webpackChunkName: "FlagsNew" */ 'routes/app/projects.$projectKey.flags.new')
                }
              />
              <Route
                path="connect"
                lazy={async () =>
                  import(/* webpackChunkName: "FlagsConnect" */ 'routes/app/projects.$projectKey.flags.connect')
                }
              />
              <Route
                lazy={async () =>
                  import(/* webpackChunkName: "FlagsModes" */ 'routes/app/projects.$projectKey.flags.modes')
                }
              >
                <Route
                  index
                  lazy={async () =>
                    import(/* webpackChunkName: "FlagsDashboard" */ 'routes/app/projects.$projectKey.flags')
                  }
                />
                <Route
                  lazy={async () =>
                    import(/* webpackChunkName: "FlagsDashboard" */ 'routes/app/projects.$projectKey.flags')
                  }
                >
                  <Route
                    path=":flagKey/environment-overview"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagsEnvironmentOverview" */ 'routes/app/projects.$projectKey.flags.$flagKey.environment-overview'
                      )
                    }
                  />
                </Route>
              </Route>
              <Route
                path=":flagKey/approvals/:approvalRequestId"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "FlagsApprovalRequest" */ 'routes/app/projects.$projectKey.flags.$flagKey.approvals.$approvalRequestId'
                  )
                }
              />
              <Route
                path=":flagKey"
                lazy={async () =>
                  import(/* webpackChunkName: "FlagEntityHome" */ 'routes/app/projects.$projectKey.flags.$flagKey')
                }
              >
                <Route index element={<Navigate to="targeting" replace />} />
                <Route
                  path="history"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagHistory" */ 'routes/app/projects.$projectKey.flags.$flagKey.history'
                    )
                  }
                />
                <Route
                  path="add-to-code"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagAddToCode" */ 'routes/app/projects.$projectKey.flags.$flagKey.add-to-code'
                    )
                  }
                />
                <Route
                  path="history/:auditId/*"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagHistoryAuditEntryHome" */ 'routes/app/projects.$projectKey.flags.$flagKey.history.$auditId'
                    )
                  }
                />
                <Route
                  path="targeting"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagTargeting"*/ 'routes/app/projects.$projectKey.flags.$flagKey.targeting'
                    )
                  }
                >
                  <Route
                    path="exclude-rules"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagTargetingExcludeRules"*/ 'routes/app/projects.$projectKey.flags.$flagKey.targeting.exclude-rules'
                      )
                    }
                  />
                  <Route
                    path="pending-changes"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagTargetingPendingChanges"*/ 'routes/app/projects.$projectKey.flags.$flagKey.targeting.pending-changes'
                      )
                    }
                  />
                  <Route
                    path="pending-changes/:pendingId"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagTargetingPendingChange"*/ 'routes/app/projects.$projectKey.flags.$flagKey.targeting.pending-changes.$pendingId'
                      )
                    }
                  />
                  <Route
                    path="conflicts/:conflictIndex"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagTargetingConflict"*/ 'routes/app/projects.$projectKey.flags.$flagKey.targeting.conflicts.$conflictIndex'
                      )
                    }
                  />
                </Route>
                <Route
                  path="settings"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagSettingsIndex"*/ 'routes/app/projects.$projectKey.flags.$flagKey.settings'
                    )
                  }
                >
                  <Route
                    path="archive"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagSettingsArchive" */ 'routes/app/projects.$projectKey.flags.$flagKey.settings.archive'
                      )
                    }
                  />
                  <Route
                    path="deprecate"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagSettingsDeprecate" */ 'routes/app/projects.$projectKey.flags.$flagKey.settings.deprecate'
                      )
                    }
                  />
                </Route>
                <Route
                  path="environments"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagEnvironmentSettingsIndex"*/ 'routes/app/projects.$projectKey.flags.$flagKey.environments'
                    )
                  }
                >
                  <Route
                    path="exclude-rules"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagEnvironmentSettingsExcludeRules"*/ 'routes/app/projects.$projectKey.flags.$flagKey.environments.exclude-rules'
                      )
                    }
                  />
                </Route>
                <Route
                  path="variations"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagVariationSettings"*/ 'routes/app/projects.$projectKey.flags.$flagKey.variations'
                    )
                  }
                >
                  <Route
                    path="pending-changes"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagVariationPendingChanges"*/ 'routes/app/projects.$projectKey.flags.$flagKey.variations.pending-changes'
                      )
                    }
                  />
                  <Route
                    path="pending-changes/:pendingId"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagVariationPendingChange"*/ 'routes/app/projects.$projectKey.flags.$flagKey.variations.pending-changes.$pendingId'
                      )
                    }
                  />
                  <Route
                    path="conflicts/:conflictIndex"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagVariationConflict"*/ 'routes/app/projects.$projectKey.flags.$flagKey.variations.conflicts.$conflictIndex'
                      )
                    }
                  />
                </Route>
                <Route
                  path="insights"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagInsights" */ 'routes/app/projects.$projectKey.flags.$flagKey.insights'
                    )
                  }
                />
                <Route
                  path="monitoring"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagMonitoring" */ 'routes/app/projects.$projectKey.flags.$flagKey.monitoring'
                    )
                  }
                />
                <Route
                  path="workflows"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagWorkflows" */ 'routes/app/projects.$projectKey.flags.$flagKey.workflows'
                    )
                  }
                >
                  <Route
                    index
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagWorkflows" */ 'routes/app/projects.$projectKey.flags.$flagKey.workflows.index'
                      )
                    }
                  />
                  <Route
                    path="new"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagWorkflowCreate" */ 'routes/app/projects.$projectKey.flags.$flagKey.workflows.new'
                      )
                    }
                  />
                  <Route
                    path=":workflowId"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "FlagWorkflowHome" */ 'routes/app/projects.$projectKey.flags.$flagKey.workflows.$workflowId'
                      )
                    }
                  />
                </Route>
                <Route
                  path="code-refs"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagCodeReferences" */ 'routes/app/projects.$projectKey.flags.$flagKey.code-refs'
                    )
                  }
                />
                <Route path="experiments/*" element={<Navigate to="../targeting" replace />} />
                <Route path="links" element={<Navigate to="../targeting" replace />} />
              </Route>
            </Route>
            <Route
              path="onboarding"
              lazy={async () =>
                import(/* webpackChunkName: "Onboarding" */ 'routes/app/projects.$projectKey.onboarding')
              }
            >
              <Route
                path=":onboardingPath"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "Onboarding" */ 'routes/app/projects.$projectKey.onboarding.$onboardingPath'
                  )
                }
              />
            </Route>
            <Route path="compare">
              <Route
                index
                lazy={async () =>
                  import(/* webpackChunkName: "FlagsCompare" */ 'routes/app/projects.$projectKey.compare')
                }
              />
              <Route
                path=":environmentPair"
                lazy={async () =>
                  import(/* webpackChunkName: "FlagsCompare" */ 'routes/app/projects.$projectKey.compare')
                }
              >
                <Route
                  path="flags/:flagKey"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "FlagsCompare" */ 'routes/app/projects.$projectKey.compare.$environments.flags.$flagKey'
                    )
                  }
                />
              </Route>
            </Route>
            <Route
              path="releases/:releasePipelineKey"
              lazy={async () =>
                import(
                  /* webpackChunkName: "Releases" */ 'routes/app/projects.$projectKey.releases.$releasePipelineKey_'
                )
              }
            >
              <Route
                index
                lazy={async () =>
                  import(
                    /* webpackChunkName: "Releases" */ 'routes/app/projects.$projectKey.releases.$releasePipelineKey'
                  )
                }
              />
            </Route>
            <Route
              path="ai-configs"
              lazy={async () =>
                import(/* webpackChunkName: "AiConfigs" */ 'routes/app/projects.$projectKey.ai-configs')
              }
            >
              <Route
                index
                lazy={async () =>
                  import(
                    /* webpackChunkName: "AiConfigsIndex" */ 'routes/app/projects.$projectKey.ai-configs.ai-configs.index'
                  )
                }
              />
              <Route
                lazy={async () =>
                  import(
                    /* webpackChunkName: "AiConfigsIndex" */ 'routes/app/projects.$projectKey.ai-configs.ai-configs.index'
                  )
                }
              >
                <Route
                  path="new"
                  lazy={async () =>
                    import(/* webpackChunkName: "AiConfigNew" */ 'routes/app/projects.$projectKey.ai-configs.new')
                  }
                />
              </Route>
              <Route
                path=":configKey"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "AiConfigDetails" */ 'routes/app/projects.$projectKey.ai-configs.$aiConfigKey'
                  )
                }
              >
                <Route index element={<Navigate to="variations" replace />} />
                <Route
                  path="variations"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "AiConfigVariations" */ 'routes/app/projects.$projectKey.ai-configs.$aiConfigKey.variations'
                    )
                  }
                />

                <Route
                  path="variations/:variationKey"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "AiConfigVariationCompareView" */ 'routes/app/projects.$projectKey.ai-configs.$aiConfigKey.variations.$variationKey'
                    )
                  }
                />

                <Route
                  path="targeting"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "AiConfigTargeting" */ 'routes/app/projects.$projectKey.ai-configs.$aiConfigKey.targeting'
                    )
                  }
                >
                  <Route
                    path="pending-changes"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "AiConfigPendingChanges" */ 'routes/app/projects.$projectKey.ai-configs.$aiConfigKey.targeting.pending-changes'
                      )
                    }
                  />
                </Route>
                <Route
                  path="monitoring"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "AiConfigMonitoring" */ 'routes/app/projects.$projectKey.ai-configs.$aiConfigKey.monitoring'
                    )
                  }
                />
                <Route
                  path="playground"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "AiConfigPlayground" */ 'routes/app/projects.$projectKey.ai-configs.$aiConfigKey.playground'
                    )
                  }
                />
              </Route>
            </Route>
            <Route
              path="settings"
              lazy={async () => import(/* webpackChunkName: "Settings" */ 'routes/app/projects.$projectKey.settings')}
            >
              <Route
                index
                lazy={async () =>
                  import(/* webpackChunkName: "SettingsIndex" */ 'routes/app/projects.$projectKey.settings.index')
                }
              />
              <Route
                path="context-kinds"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ContextsKindManagement" */ 'routes/app/projects.$projectKey.settings.context-kinds'
                  )
                }
              />
              <Route
                path="model-configs"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ModelConfigs" */ 'routes/app/projects.$projectKey.settings.model-configs'
                  )
                }
              />
              <Route
                path="model-configs/:configKey"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ModelConfigsIndividual" */ 'routes/app/projects.$projectKey.settings.model-configs.$modelConfigKey'
                  )
                }
              />
              <Route
                path="environments"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "EnvironmentsSettings" */ 'routes/app/projects.$projectKey.settings.environments'
                  )
                }
              >
                <Route
                  path="new"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: 'EnvironmentCreatorContainer' */ 'routes/app/projects.$projectKey.settings.environments.new'
                    )
                  }
                />

                <Route
                  path=":envKey/keys"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: 'EnvironmentKeys' */ 'routes/app/projects.$projectKey.settings.environments.$envKey.keys'
                    )
                  }
                />

                <Route
                  path=":envKey/edit"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: 'EnvironmentEditorContainer' */ 'routes/app/projects.$projectKey.settings.environments.$envKey.edit'
                    )
                  }
                />
                <Route
                  path=":envKey/approval-settings"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: 'EnvironmentApprovalSettingsFormContainer' */ 'routes/app/projects.$projectKey.settings.environments.$envKey.approval-settings'
                    )
                  }
                />
              </Route>
              <Route
                path="payload-filters"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "PayloadFiltersSettings_" */ 'routes/app/projects.$projectKey.settings.payload-filters_'
                  )
                }
              >
                <Route
                  index
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "PayloadFiltersSettings" */ 'routes/app/projects.$projectKey.settings.payload-filters'
                    )
                  }
                />
                <Route
                  path=":filterKey/edit"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "PayloadFiltersEditSettings" */ 'routes/app/projects.$projectKey.settings.payload-filters.$filterKey.edit'
                    )
                  }
                />
              </Route>

              <Route
                path="flags"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ManageProjectFlagDefaults" */ 'routes/app/projects.$projectKey.settings.flag-defaults'
                  )
                }
              />

              <Route
                path="release-pipelines"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "OrganizationReleasePipelinesIndex" */ 'routes/app/projects.$projectKey.settings.release-pipelines'
                  )
                }
              >
                <Route
                  index
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "OrganizationReleasePipelinesIndex" */ 'routes/app/projects.$projectKey.settings.release-pipelines.index'
                    )
                  }
                />
                <Route
                  path="new"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "OrganizationReleasePipelineNew" */ 'routes/app/projects.$projectKey.settings.release-pipelines.new'
                    )
                  }
                />
                <Route
                  path=":releasePipelineKey"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "OrganizationReleasePipelineDetails" */ 'routes/app/projects.$projectKey.settings.release-pipelines.$releasePipelineKey'
                    )
                  }
                />
              </Route>
            </Route>

            <Route
              path="contexts"
              lazy={async () => import(/* webpackChunkName: "Contexts" */ 'routes/app/projects.$projectKey.contexts')}
            >
              <Route
                index
                lazy={async () =>
                  import(/* webpackChunkName: "ContextsIndex" */ 'routes/app/projects.$projectKey.contexts.index')
                }
              />
              <Route path="kinds">
                <Route
                  index
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ContextsKindManagement" */ 'routes/app/projects.$projectKey.contexts.kinds.index'
                    )
                  }
                />
              </Route>
              <Route
                path="kinds/:kind/instances/:key"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ContextsDetails" */ 'routes/app/projects.$projectKey.contexts.$kind-$key'
                  )
                }
              />
              <Route
                path="instances/:instanceId"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ContextInstanceDetails" */ 'routes/app/projects.$projectKey.contexts.$instanceId'
                  )
                }
              />
            </Route>
            <Route
              path="segments"
              lazy={async () => import(/* webpackChunkName: "Segments" */ 'routes/app/projects.$projectKey.segments')}
            >
              <Route
                index
                lazy={async () =>
                  import(/* webpackChunkName: "SegmentsIndex" */ 'routes/app/projects.$projectKey.segments.index')
                }
              />
              <Route
                lazy={async () =>
                  import(/* webpackChunkName: "SegmentsIndex" */ 'routes/app/projects.$projectKey.segments.index')
                }
              >
                <Route
                  path="new"
                  lazy={async () =>
                    import(/* webpackChunkName: "SegmentsNew" */ 'routes/app/projects.$projectKey.segments.new')
                  }
                />
              </Route>
              <Route
                path=":segmentKey"
                lazy={async () =>
                  import(/* webpackChunkName: "Segment" */ 'routes/app/projects.$projectKey.segments.$segmentKey')
                }
              >
                <Route index element={<Navigate to="targeting" replace />} />
                <Route
                  path="targeting"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "SegmentTargeting" */ 'routes/app/projects.$projectKey.segments.$segmentKey.targeting'
                    )
                  }
                >
                  <Route
                    path="exports/:exportKey"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "SegmentDownload" */ 'routes/app/projects.$projectKey.segments.$segmentKey.targeting.exports.$exportKey'
                      )
                    }
                  />
                </Route>
                <Route
                  path="flags"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "SegmentFlags" */ 'routes/app/projects.$projectKey.segments.$segmentKey.flags'
                    )
                  }
                />
                <Route
                  path="overview"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "SegmentOverview" */ 'routes/app/projects.$projectKey.segments.$segmentKey.overview'
                    )
                  }
                />
                <Route
                  path="history/*"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "SegmentHistory" */ 'routes/app/projects.$projectKey.segments.$segmentKey.history'
                    )
                  }
                />
                <Route
                  path="history/:auditId/*"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "SegmentHistoryAuditEntryHome" */ 'routes/app/projects.$projectKey.segments.$segmentKey.history.$auditId'
                    )
                  }
                />
                <Route
                  path="settings"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "SegmentSettings" */ 'routes/app/projects.$projectKey.segments.$segmentKey.settings'
                    )
                  }
                />
              </Route>
            </Route>
            <Route
              path="live"
              lazy={async () => import(/* webpackChunkName: "LiveEvents" */ 'routes/app/projects.$projectKey.live')}
            >
              <Route path="flags">
                <Route
                  index
                  lazy={async () =>
                    import(/* webpackChunkName: "LiveEventsFlags" */ 'routes/app/projects.$projectKey.live.flags')
                  }
                />
                <Route
                  path=":flagKey"
                  lazy={async () =>
                    import(/* webpackChunkName: "LiveEventsFlags" */ 'routes/app/projects.$projectKey.live.flags')
                  }
                />
              </Route>
            </Route>
            <Route
              path="sessions"
              lazy={async () => import(/* webpackChunkName: "Sessions" */ 'routes/app/projects.$projectKey.sessions')}
            />
            <Route
              path="sessions/:sessionId/*"
              lazy={async () =>
                import(/* webpackChunkName: "SessionDetails" */ 'routes/app/projects.$projectKey.sessions.$sessionId')
              }
            />
            <Route
              path="errors"
              lazy={async () =>
                import(/* webpackChunkName: "ErrorMonitoringLayout" */ 'routes/app/projects.$projectKey.errors_')
              }
            >
              <Route
                lazy={async () =>
                  import(/* webpackChunkName: "ErrorMonitoring" */ 'routes/app/projects.$projectKey.errors')
                }
              >
                <Route
                  index
                  lazy={async () =>
                    import(/* webpackChunkName: "ErrorMonitoringIndex" */ 'routes/app/projects.$projectKey.errors')
                  }
                />
              </Route>
              <Route
                path=":issueId"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ErrorMonitoringIssue" */ 'routes/app/projects.$projectKey.errors.$issueId'
                  )
                }
              />
            </Route>
            <Route
              path="holdouts"
              lazy={async () => import(/* webpackChunkName: "Holdouts" */ 'routes/app/projects.$projectKey.holdouts')}
            >
              <Route
                path="create"
                lazy={async () =>
                  import(/* webpackChunkName: "holdoutsCreatePage" */ 'routes/app/projects.$projectKey.holdouts.create')
                }
              ></Route>
              <Route
                lazy={async () =>
                  import(
                    /* webpackChunkName: "HoldoutsDashboardLayout" */ 'routes/app/projects.$projectKey.holdouts.dashboard'
                  )
                }
              >
                <Route
                  index
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "HoldoutDashboardIndex" */ 'routes/app/projects.$projectKey.holdouts.dashboard.index'
                    )
                  }
                />
              </Route>
              <Route
                path=":holdoutKey"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "HoldoutDetails" */ 'routes/app/projects.$projectKey.holdouts.$holdoutKey'
                  )
                }
              >
                <Route
                  path="details"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "HoldoutDetails" */ 'routes/app/projects.$projectKey.holdouts.$holdoutKey.details'
                    )
                  }
                />
              </Route>
            </Route>
            <Route
              path="experiments"
              lazy={async () =>
                import(/* webpackChunkName: "Experiments" */ 'routes/app/projects.$projectKey.experiments')
              }
            >
              <Route
                path="create"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ExperimentsCreate" */ 'routes/app/projects.$projectKey.experiments.create'
                  )
                }
              />
              {/* Experiment dashboard */}

              <Route
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ExperimentsDashboardLayout" */ 'routes/app/projects.$projectKey.experiments.dashboard'
                  )
                }
              >
                <Route
                  index
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ExperimentDashboardIndex" */ 'routes/app/projects.$projectKey.experiments.dashboard.index'
                    )
                  }
                />

                <Route
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ExperimentDashboardIndex" */ 'routes/app/projects.$projectKey.experiments.dashboard.index'
                    )
                  }
                >
                  <Route
                    path="new"
                    lazy={async () =>
                      import(/* webpackChunkName: "ExperimentNew" */ 'routes/app/projects.$projectKey.experiments.new')
                    }
                  />
                </Route>
                <Route
                  path=":experimentId/details"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ExperimentDetails" */ 'routes/app/projects.$projectKey.experiments.$experimentId.details'
                    )
                  }
                />
              </Route>

              {/* Experiment details */}
              <Route
                path=":experimentKey"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "Experiment" */ 'routes/app/projects.$projectKey.experiments.$experimentKey'
                  )
                }
              >
                <Route
                  path="design"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ExperimentDesign" */ 'routes/app/projects.$projectKey.experiments.$experimentKey.design'
                    )
                  }
                />
                <Route
                  path="results"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ExperimentResults" */ 'routes/app/projects.$projectKey.experiments.$experimentKey.results'
                    )
                  }
                />
                <Route
                  path="iterations"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ExperimentIterations" */ 'routes/app/projects.$projectKey.experiments.$experimentKey.iterations'
                    )
                  }
                />
                <Route
                  path="iterations/:iterationId"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ExperimentIteration" */ 'routes/app/projects.$projectKey.experiments.$experimentKey.iterations.$iterationId'
                    )
                  }
                />
                <Route
                  path="events"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ExperimentEvents" */ 'routes/app/projects.$projectKey.experiments.$experimentKey.events'
                    )
                  }
                />
                <Route
                  path="traffic"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ExperimentEvents" */ 'routes/app/projects.$projectKey.experiments.$experimentKey.traffic'
                    )
                  }
                />
              </Route>
              {/* This route has a different layout so it stays outside of the other nested routes */}
            </Route>
            {/* End of experimentation routes */}
            <Route
              path="metrics"
              lazy={async () => import(/* webpackChunkName: "Metrics_" */ 'routes/app/projects.$projectKey.metrics_')}
            >
              <Route
                lazy={async () => import(/* webpackChunkName: "Metrics" */ 'routes/app/projects.$projectKey.metrics')}
              >
                <Route
                  index
                  lazy={async () =>
                    import(/* webpackChunkName: "MetricsIndex" */ 'routes/app/projects.$projectKey.metrics.index')
                  }
                />
                <Route
                  lazy={async () =>
                    import(/* webpackChunkName: "MetricsIndex" */ 'routes/app/projects.$projectKey.metrics.index')
                  }
                >
                  <Route
                    path="new"
                    lazy={async () =>
                      import(/* webpackChunkName: "MetricsNew" */ 'routes/app/projects.$projectKey.metrics.new')
                    }
                  />
                </Route>
                <Route
                  path="analytics"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "MetricAnalytics" */ 'routes/app/projects.$projectKey.metrics.analytics'
                    )
                  }
                >
                  <Route
                    path="new/:analyticsQueryResultId"
                    lazy={async () =>
                      import(
                        /* webpackChunkName: "MetricAnalyticsQueryResults" */ 'routes/app/projects.$projectKey.metrics.analytics.new.$analyticsQueryResultId'
                      )
                    }
                  />
                </Route>
                <Route
                  path="discover"
                  lazy={async () =>
                    import(/* webpackChunkName: "MetricDiscovery" */ 'routes/app/projects.$projectKey.metrics.discover')
                  }
                />
              </Route>

              <Route
                path=":metricKey/details"
                lazy={async () =>
                  import(/* webpackChunkName: "Metric" */ 'routes/app/projects.$projectKey.metrics.$metricKey.details')
                }
              />
              <Route
                path="groups/:metricGroupKey/details"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "MetricGroup" */ 'routes/app/projects.$projectKey.metrics.groups.$metricGroupKey.details'
                  )
                }
              />
            </Route>
            <Route
              path="metrics/:metricKey/impact"
              lazy={async () =>
                import(
                  /* webpackChunkName: "MetricImpactOverview" */ 'routes/app/projects.$projectKey.metrics.$metricKey.impact'
                )
              }
            />
            <Route
              path="observe"
              lazy={async () => import(/* webpackChunkName: "Observe" */ 'routes/app/projects.$projectKey.observe')}
            >
              <Route
                path="new-metric"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ObserveNewMetric" */ 'routes/app/projects.$projectKey.observe.new-metric'
                  )
                }
              />
              <Route path="expand/:eventKind/:eventKey" element={null} />
            </Route>
            <Route
              path="approvals"
              lazy={async () =>
                import(/* webpackChunkName: "ApprovalDashboard_" */ 'routes/app/projects.$projectKey.approvals_')
              }
            >
              <Route
                index
                lazy={async () =>
                  import(/* webpackChunkName: "ApprovalDashboard" */ 'routes/app/projects.$projectKey.approvals')
                }
              />
              <Route
                path=":approvalRequestId"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "Approval" */ 'routes/app/projects.$projectKey.approvals.$approvalRequestId'
                  )
                }
              />
            </Route>
            <Route
              path="install-sdk"
              lazy={async () =>
                import(/* webpackChunkName: "ConnectAnSdk" */ 'routes/app/projects.$projectKey.install-sdk')
              }
            />
          </Route>
        </Route>

        <Route path="/profile" lazy={async () => import(/* webpackChunkName: "Profile" */ 'routes/app/profile')}>
          <Route index lazy={async () => import(/* webpackChunkName: "ProfileIndex" */ 'routes/app/profile.index')} />
        </Route>

        <Route
          path="/settings"
          lazy={async () => import(/* webpackChunkName: "OrganizationSettings" */ 'routes/app/settings')}
        >
          <Route index element={<Navigate to="general" replace />} />
          <Route
            path="general"
            lazy={async () =>
              import(/* webpackChunkName: "OrganizationGeneralSettings" */ 'routes/app/settings.general')
            }
          />
          <Route
            path="members"
            lazy={async () => import(/* webpackChunkName: "OrganizationMembers_" */ 'routes/app/settings.members_')}
          >
            <Route
              index
              lazy={async () => import(/* webpackChunkName: "OrganizationMembers" */ 'routes/app/settings.members')}
            />
            <Route
              lazy={async () => import(/* webpackChunkName: "OrganizationMembers" */ 'routes/app/settings.members')}
            >
              <Route
                path="invite/:inviteEmail?"
                lazy={async () =>
                  import(/* webpackChunkName: "OrganizationMembersInvite" */ 'routes/app/settings.members.invite')
                }
              />
            </Route>
            <Route
              path=":memberId"
              lazy={async () =>
                import(/* webpackChunkName: "OrganizationMember" */ 'routes/app/settings.members.$memberId')
              }
            >
              <Route index element={<Navigate to="permissions" replace />} />
              <Route
                path="permissions"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "OrganizationMemberPermissions" */ 'routes/app/settings.members.$memberId.permissions'
                  )
                }
              />
              <Route
                path="teams"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "OrganizationMemberTeams" */ 'routes/app/settings.members.$memberId.teams'
                  )
                }
              />
              <Route
                path="history/*"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "OrganizationMemberHistory" */ 'routes/app/settings.members.$memberId.history'
                  )
                }
              />
              <Route
                path="settings"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "OrganizationMemberSettings" */ 'routes/app/settings.members.$memberId.settings'
                  )
                }
              />
            </Route>
          </Route>
          <Route
            path="teams"
            lazy={async () => import(/* webpackChunkName: "OrganizationTeams_" */ 'routes/app/settings.teams_')}
          >
            <Route
              index
              lazy={async () => import(/* webpackChunkName: "OrganizationTeams" */ 'routes/app/settings.teams')}
            />
            <Route
              path=":teamKey"
              lazy={async () => import(/* webpackChunkName: "OrganizationTeam" */ 'routes/app/settings.teams.$teamKey')}
            >
              <Route index element={<Navigate to="members" replace />} />
              <Route
                path="members"
                lazy={async () =>
                  import(/* webpackChunkName: "OrganizationTeamMembers" */ 'routes/app/settings.teams.$teamKey.members')
                }
              />
              <Route
                path="permissions"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "OrganizationTeamPermissions" */ 'routes/app/settings.teams.$teamKey.permissions'
                  )
                }
              />
              <Route
                path="history/*"
                lazy={async () =>
                  import(/* webpackChunkName: "OrganizationTeamHistory" */ 'routes/app/settings.teams.$teamKey.history')
                }
              />
              <Route
                path="settings"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "OrganizationTeamSettings" */ 'routes/app/settings.teams.$teamKey.settings'
                  )
                }
              />
            </Route>
          </Route>
          <Route path="projects">
            <Route
              index
              lazy={async () => import(/* webpackChunkName: "OrganizationProjects" */ 'routes/app/settings.projects')}
            />
          </Route>
          <Route
            path="applications"
            lazy={async () => import(/* webpackChunkName: "Applications" */ 'routes/app/settings.applications')}
          >
            <Route index lazy={ApplicationsListContainer} />
            <Route lazy={ApplicationsListContainer}>
              <Route
                path="new"
                lazy={async () =>
                  import(/* webpackChunkName: "ApplicationsNew" */ 'routes/app/settings.applications.new')
                }
              />
              <Route
                path=":applicationKey/edit"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ApplicationEdit" */ 'routes/app/settings.applications.$applicationKey.edit'
                  )
                }
              />
            </Route>
            <Route
              path=":applicationKey"
              lazy={async () =>
                import(/* webpackChunkName: "ApplicationLayout" */ 'routes/app/settings.applications.$applicationKey')
              }
            >
              <Route index element={<Navigate to="versions" replace />} />
              <Route
                path="source-maps"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ApplicationSourcemaps" */ 'routes/app/settings.applications.$applicationKey.sourcemaps'
                  )
                }
              />
              <Route
                path="versions"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ApplicationVersions" */ 'routes/app/settings.applications.$applicationKey.versions'
                  )
                }
              >
                <Route
                  path="new"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ApplicationVersionsNew" */ 'routes/app/settings.applications.$applicationKey.versions.new'
                    )
                  }
                />
                <Route
                  path=":versionKey/support-status"
                  lazy={async () =>
                    import(
                      /* webpackChunkName: "ApplicationVersionSupportStatus" */ 'routes/app/settings.applications.$applicationKey.versions.support-status'
                    )
                  }
                />
              </Route>

              <Route
                path="history/*"
                lazy={async () =>
                  import(
                    /* webpackChunkName: "ApplicationHistory" */ 'routes/app/settings.applications.$applicationKey.history'
                  )
                }
              />
            </Route>
          </Route>
          <Route path="roles" lazy={async () => import(/* webpackChunkName: "Roles" */ 'routes/app/settings.roles_')}>
            <Route
              index
              lazy={async () =>
                import(/* webpackChunkName: "OrganizationRolesIndex" */ 'routes/app/settings.roles.index')
              }
            />

            {/* UPDATED ROLES */}
            <Route lazy={async () => import(/* webpackChunkName: "OrganizationRoles" */ 'routes/app/settings.roles')}>
              <Route
                path="project"
                lazy={async () => import(/* webpackChunkName: "ProjectRoles"*/ 'routes/app/settings.roles.project')}
              >
                <Route
                  path=":key/view"
                  lazy={async () =>
                    import(/* webpackChunkName: "OrganizationRolesView" */ 'routes/app/settings.roles.$key.view')
                  }
                />
              </Route>
              <Route
                path="organization"
                lazy={async () => import(/* webpackChunkName: "OrgRoles"*/ 'routes/app/settings.roles.organization')}
              >
                <Route
                  path=":key/view"
                  lazy={async () =>
                    import(/* webpackChunkName: "OrganizationRolesView" */ 'routes/app/settings.roles.$key.view')
                  }
                />
              </Route>
            </Route>

            <Route
              path="new"
              lazy={async () => import(/* webpackChunkName: "OrganizationRolesNew" */ 'routes/app/settings.roles.new')}
            />
            <Route
              path=":key/edit"
              lazy={async () =>
                import(/* webpackChunkName: "OrganizationRolesEdit" */ 'routes/app/settings.roles.$key.edit')
              }
            />
            <Route
              path=":key/view"
              lazy={async () =>
                import(/* webpackChunkName: "OrganizationRolesView" */ 'routes/app/settings.roles.$key.view')
              }
            />
          </Route>

          <Route
            path="relay"
            lazy={async () => import(/* webpackChunkName: "OrganizationRelayProxies" */ 'routes/app/settings.relay')}
          >
            <Route
              path="new"
              lazy={async () =>
                import(/* webpackChunkName: "OrganizationRelayProxiesNew" */ 'routes/app/settings.relay.new')
              }
            />
            <Route
              path=":id/edit"
              lazy={async () =>
                import(/* webpackChunkName: "OrganizationRelayProxiesEdit" */ 'routes/app/settings.relay.$id.edit')
              }
            />
          </Route>
          <Route
            path="security"
            lazy={async () => import(/* webpackChunkName: "OrganizationSecurity" */ 'routes/app/settings.security')}
          >
            <Route
              path="saml/config"
              lazy={async () =>
                import(/* webpackChunkName: 'OrganizationSAMLConfig' */ 'routes/app/settings.security.saml.config')
              }
            />
          </Route>
          <Route
            path="authorization"
            lazy={async () =>
              import(/* webpackChunkName: "OrganizationAuthorization" */ 'routes/app/settings.authorization')
            }
          >
            <Route
              path="roles/:key"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationAuthorizationViewRole" */ 'routes/app/settings.authorization.roles.$key'
                )
              }
            />
            <Route
              path="tokens/new"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationAuthorizationTokenNew" */ 'routes/app/settings.authorization.tokens.new'
                )
              }
            />
            <Route
              path="tokens/:tokenId/clone"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationAuthorizationTokenClone" */ 'routes/app/settings.authorization.tokens.$tokenId.clone'
                )
              }
            />
            <Route
              path="tokens/:tokenId/edit"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationAuthorizationTokenEdit" */ 'routes/app/settings.authorization.tokens.$tokenId.edit'
                )
              }
            />
            <Route
              path="tokens/:tokenId/reset"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationAuthorizationTokenReset" */ 'routes/app/settings.authorization.tokens.$tokenId.reset'
                )
              }
            />
            <Route
              path="oauth/:appId/review"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationAuthorizationOAuthReview" */ 'routes/app/settings.authorization.oauth.$appId.review'
                )
              }
            />
          </Route>
          <Route path="tokens/*" element={<Navigate to="../authorization" replace />} />
          <Route
            path="billing"
            lazy={async () => import(/* webpackChunkName: "OrganizationBilling" */ 'routes/app/settings.billing')}
          >
            <Route
              index
              lazy={async () =>
                import(/* webpackChunkName: "OrganizationBillingIndex" */ 'routes/app/settings.billing.index')
              }
            />
            <Route
              path="plans"
              lazy={async () =>
                import(/* webpackChunkName: "OrganizationBillingPlans" */ 'routes/app/settings.billing.plans')
              }
            />
            <Route
              path="plans/review"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationBillingPlanReview" */ 'routes/app/settings.billing.plans.review'
                )
              }
            />
          </Route>
          <Route
            path="usage"
            lazy={async () => import(/* webpackChunkName: "OrganizationUsage" */ 'routes/app/settings.usage')}
          >
            <Route index element={<Navigate to="details/overview" replace />} />
            <Route
              path="details/overview"
              lazy={async () =>
                import(/* webpackChunkName: "OrganizationUsageIndex" */ 'routes/app/settings.usage.details.overview')
              }
            />
            <Route
              path="details/host"
              lazy={async () =>
                import(/* webpackChunkName: "OrganizationUsageDetailsHost" */ 'routes/app/settings.usage.details.host')
              }
            />
            <Route
              path="details/server"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationUsageDetailsServer" */ 'routes/app/settings.usage.details.server'
                )
              }
            />
            <Route
              path="details/client"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationUsageDetailsClient" */ 'routes/app/settings.usage.details.client'
                )
              }
            />
            <Route
              path="details/experiments"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationUsageDetailsExperiments" */ 'routes/app/settings.usage.details.experiments'
                )
              }
            />
            <Route
              path="details/data-export"
              lazy={async () =>
                import(
                  /* webpackChunkName: "OrganizationUsageDetailsDataExport" */ 'routes/app/settings.usage.details.data-export'
                )
              }
            />
          </Route>
          <Route
            path="integrations"
            lazy={async () => import(/* webpackChunkName: "Integrations" */ 'routes/app/settings.integrations')}
          >
            <Route
              path="destinations/new"
              lazy={async () =>
                import(
                  /* webpackChunkName: "IntegrationsDestinationsNew" */ 'routes/app/settings.integrations.destinations.new'
                )
              }
            />
            <Route
              path="destinations/:destinationId/edit"
              lazy={async () =>
                import(
                  /* webpackChunkName: "IntegrationsDestinationsNew" */ 'routes/app/settings.integrations.destinations.edit'
                )
              }
            />
            <Route
              path="webhooks/new"
              lazy={async () =>
                import(/* webpackChunkName: "IntegrationsWebhookNew" */ 'routes/app/settings.integrations.webhooks.new')
              }
            />
            <Route
              path="webhooks/:webhookId/edit"
              lazy={async () =>
                import(
                  /* webpackChunkName: "IntegrationsWebhookEdit" */ 'routes/app/settings.integrations.webhooks.edit'
                )
              }
            />
            <Route
              path="slack/new"
              lazy={async () =>
                import(/* webpackChunkName: "IntegrationsSlackNew" */ 'routes/app/settings.integrations.slack.new')
              }
            />
            <Route
              path="slack/:slackHookId/edit"
              lazy={async () =>
                import(/* webpackChunkName: "IntegrationsSlackEdit" */ 'routes/app/settings.integrations.slack.edit')
              }
            />
            <Route
              path="coderefs/:id/edit"
              lazy={async () =>
                import(
                  /* webpackChunkName: "IntegrationsRepositoryEdit" */ 'routes/app/settings.integrations.repositories.edit'
                )
              }
            />
            <Route
              path=":kind/new"
              lazy={async () =>
                import(
                  /* webpackChunkName: "IntegrationsGoaltenderSubscriptionNew" */ 'routes/app/settings.integrations.goaltender.subscriptions.new'
                )
              }
            />
            <Route
              path=":kind/:subscriptionId/errors/view"
              lazy={async () =>
                import(
                  /* webpackChunkName: "IntegrationsGoaltenderSubscriptionErrors" */ 'routes/app/settings.integrations.goaltender.subscriptions.errors'
                )
              }
            />
            <Route
              path=":kind/:subscriptionId/edit"
              lazy={async () =>
                import(
                  /* webpackChunkName: "IntegrationsGoaltenderSubscriptionEdit" */ 'routes/app/settings.integrations.goaltender.subscriptions.edit'
                )
              }
            />
            <Route
              path="sentry/show"
              lazy={async () =>
                import(/* webpackChunkName: "IntegrationsSentry" */ 'routes/app/settings.integrations.sentry')
              }
            />
            <Route
              path=":kind/show"
              lazy={async () =>
                import(
                  /* webpackChunkName: "IntegrationsGoaltenderLearnMore" */ 'routes/app/settings.integrations.goaltender.learn-more'
                )
              }
            />
          </Route>
          <Route
            path="history/*"
            lazy={async () => import(/* webpackChunkName: "OrganizationHistory" */ 'routes/app/settings.history')}
          />
          <Route path="profile" element={<Navigate to="/profile" replace />} />
        </Route>
      </Route>
      <Route
        path="/internal/new-hires"
        lazy={async () => import(/* webpackChunkName: "newHires.entry" */ 'components/NewHires/NewHiresContainer')}
      />
    </Route>
    <Route
      path="/verify-email"
      lazy={async () => import(/* webpackChunkName: "EmailVerification" */ 'routes/app/verify-email')}
    />
    {/* special unauthenticated route for privilege escalation (sometimes called "beast mode") */}
    <Route
      path="/settings/escalate"
      lazy={async () => import(/* webpackChunkName: "EscalatePrivileges" */ 'components/EscalatePrivilegesContainer')}
    />
    <Route path="*" lazy={async () => import(/* webpackChunkName: "404NotFound" */ 'routes/app/404')} />
  </Route>
);

function OwnershipWatcher() {
  const routes = useMemo(() => appRoutes(), []);
  const matchingPathPattern = useMatchingAppPathPattern(routes);

  useEffect(() => {
    if (matchingPathPattern) {
      const owner = appRouteOwnership[matchingPathPattern];
      setRouteContext(matchingPathPattern, owner);
    }
  }, [matchingPathPattern]);

  return <Outlet />;
}
