import { useEffect } from 'react';
import { announce } from '@react-aria/live-announcer';

/**
 * Used to initialize the announcer so that JAWS and NVDA pick up the first announcement correctly.
 */
const Announcer = () => {
  useEffect(() => {
    announce('', 'polite', 300);
  }, []);
  return <></>;
};
/* eslint-disable import/no-default-export */
export default Announcer;
