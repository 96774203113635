import { FeatureFlag } from './types';

export const getInUseVariations = (flag: FeatureFlag) => {
  const environments = Object.values(flag.environments ?? {});

  return new Set(
    environments
      .flatMap((env) => {
        const ctxValues = env?.contextTargets?.map((ctx) => ctx.variation) ?? [];
        const fallthroughValue = env?.fallthrough?.variation;
        return [...ctxValues, fallthroughValue];
      })
      .filter((v) => v !== undefined),
  );
};
