import { isFlagOnForEnvironment } from './isFlagOnForEnvironment';
import { FeatureFlag, FeatureFlagConfig, Variation } from './types';

export function getFlagActiveVariationsFromSummary(config: FeatureFlagConfig, variations: FeatureFlag['variations']) {
  const activeVariations: Array<[variationIndex: number, variation: Variation]> = [];

  if (!config._summary?.variations) {
    return activeVariations;
  }

  Object.keys(config._summary.variations).forEach((variationIndex) => {
    const variationSummary = config._summary?.variations[variationIndex];
    if (!variationSummary) {
      return;
    }

    if (
      isFlagOnForEnvironment(config)
        ? variationSummary.rules > 0 ||
          variationSummary.targets > 0 ||
          variationSummary.contextTargets > 0 ||
          variationSummary.isFallthrough ||
          (variationSummary.rollout && variationSummary.rollout > 0) ||
          ((config._summary?.prerequisites ?? 0) > 0 && variationSummary.isOff)
        : variationSummary.isOff
    ) {
      const index = Number(variationIndex);
      if (variations[index]) {
        activeVariations.push([Number(variationIndex), variations[index]]);
      }
    }
  });

  return activeVariations;
}
