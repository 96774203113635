import { isMobileContextKind } from '@gonfalon/context-kinds';

import { Clause } from './types';

export function isMobileClause(clause: Clause) {
  if (clause.contextKind) {
    return isMobileContextKind(clause.contextKind);
  }
  return false;
}
