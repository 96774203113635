import { createSearchParamsHook } from '@gonfalon/router';

import { parseContextKindsSearchParamsFromBrowser } from './parseContextKindsSearchParamsFromBrowser';
import { seralizeContextKindsSearchParamsToBrowser } from './seralizeContextKindsSearchParamsToBrowser';
import { UIContextsKindsView } from './types';

export const useContextKindsParams = createSearchParamsHook<UIContextsKindsView>({
  parse: parseContextKindsSearchParamsFromBrowser,
  serialize: seralizeContextKindsSearchParamsToBrowser,
});
