import { queryfilterFromString } from '@gonfalon/queryfilter';

import { UIContextsView } from './types';

export function parseUIContextsViewFromBrowser(searchParams: URLSearchParams) {
  const ui: UIContextsView = {};
  const filter = searchParams.get('filter');
  if (filter) {
    const parse = queryfilterFromString(decodeURIComponent(filter));
    if (parse.ok) {
      ui.filter = parse.val;
    }
  }

  return ui;
}
