import { getFlagVariationDisplayValue, VariationDisplay } from '@gonfalon/flags';
import { stringifyValue } from '@gonfalon/types';
import { ComboBox, Dialog, Group, Input, ListBox, ListBoxItem } from '@launchpad-ui/components';

import { Variation } from '../types';

import { VariationPickerProps } from './types';

import styles from './VariationPicker.module.css';

interface Item {
  id: string;
  variation: Variation;
  variationIndex: number;
}

export function VariationPickerDialog({
  variations,
  onSelectionChange,
}: Omit<VariationPickerProps, 'selectedVariationIndex'>) {
  const items: Item[] = variations.map((v, i) => ({
    id: v._id ?? stringifyValue(v.value),
    variation: v,
    variationIndex: i,
  }));

  return (
    <Dialog>
      {({ close }) => (
        <ComboBox
          aria-label="Select a variation"
          menuTrigger="focus"
          autoFocus
          allowsEmptyCollection
          shouldFocusWrap
          defaultItems={items}
          onSelectionChange={(key) => {
            if (typeof key === 'string') {
              if (typeof key === 'string') {
                const selectedItem = items.find((v) => v.id === key);
                if (selectedItem) {
                  onSelectionChange(selectedItem.variation, selectedItem.variationIndex);
                }
              }
              close();
            }
          }}
        >
          <Group>
            <Input placeholder="Search variations" />
          </Group>
          <ListBox<Item> className={styles.ListBox}>
            {(item) => (
              <ListBoxItem id={item.variation._id} textValue={getFlagVariationDisplayValue(item.variation)}>
                <div className={styles.Variation}>
                  <VariationDisplay variation={item.variation} variationIndex={item.variationIndex} />
                </div>
              </ListBoxItem>
            )}
          </ListBox>
        </ComboBox>
      )}
    </Dialog>
  );
}
