import { z } from 'zod';

// This schema is used to validate context objects (single kind and multi kind)
export const contextSchema = z.object({
  context: z
    .object({
      kind: z.union([z.string().nonempty('Kind is required'), z.literal('multi')]),
      key: z.string().nonempty('Key is required').optional(),
    })
    .catchall(z.any()),
});
