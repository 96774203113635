import { queryfilterToString } from '@gonfalon/queryfilter';
import { PostContextsParams } from '@gonfalon/rest-api';

import { UIContextsView } from './types';

export function serializeUIContextsViewForAPI(ui: UIContextsView) {
  const api: PostContextsParams = {};
  if (ui.filter) {
    api.filter = queryfilterToString(ui.filter);
  }
  return api;
}
