import { FeatureFlagConfig } from './types';

export function getAllTargetsForVariation(
  flagConfig: Pick<FeatureFlagConfig, 'contextTargets' | 'targets'>,
  variationIdx: number,
) {
  const contextTargets = flagConfig.contextTargets || [];
  const userTargets = flagConfig.targets || [];

  const filteredContextTargets = contextTargets.filter(
    (target) => target.variation === variationIdx && target.contextKind !== 'user',
  );

  const filteredUserTargets = userTargets.filter((target) => target.variation === variationIdx);

  return [...filteredUserTargets, ...filteredContextTargets];
}
