import { couldBeBoolean } from './couldBeBoolean';
import { couldBeNumber } from './couldBeNumber';
import { isJSONString } from './isJSONString';
import { JSONValue } from './json';
import { stringifyValue } from './stringifyValue';

export function coerceToType(value: unknown, newType: 'number'): value is number;
export function coerceToType(value: unknown, newType: 'string'): value is string;
export function coerceToType(value: unknown, newType: 'boolean'): value is boolean;
export function coerceToType(value: unknown, newType: 'json'): value is JSONValue;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function coerceToType(value: unknown, newType?: 'number' | 'string' | 'boolean' | 'json'): any;
export function coerceToType(value: unknown, newType?: 'number' | 'string' | 'boolean' | 'json') {
  if (typeof value === newType) {
    return value;
  }
  if (newType === 'string') {
    return stringifyValue(value);
  } else if (newType === 'number') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return couldBeNumber(value) ? +(value as any) : value;
  } else if (newType === 'boolean') {
    return couldBeBoolean(value) ? value === 'true' : value;
  } else if (newType === 'json' && isJSONString(value)) {
    return JSON.parse(value);
  }
  return value;
}
