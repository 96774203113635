import { useState } from 'react';
import { useDebouncedString } from '@gonfalon/async';
import { getFeatureFlagQuery, getFeatureFlagsQuery } from '@gonfalon/rest-api';
import {
  ComboBox,
  ComboBoxClearButton,
  Dialog,
  Group,
  Input,
  ListBox,
  ListBoxItem,
  ProgressBar,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';

import { getFlagConfigurationForEnvironment } from '../getFlagConfigurationForEnvironment';
import { isFlagOnForEnvironment } from '../isFlagOnForEnvironment';
import { FeatureFlag } from '../types';

import { FlagStatus } from './FlagStatus';
import { TargetingTag } from './TargetingTag';
import { FlagPickerProps } from './types';

import styles from './FlagPicker.module.css';

const MAX_OPTION_COUNT = 10;
const SEARCH_DEBOUNCE_MS = 200;

export function FlagPickerDialog({
  projectKey,
  environmentKey,
  includeStatus = false,
  includeTargeting = false,
  onSelectionChange,
}: Omit<FlagPickerProps, 'selectedFlagKey'>) {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebouncedString(searchTerm, SEARCH_DEBOUNCE_MS);

  const queryClient = useQueryClient();

  const { status, fetchStatus, data } = useQuery({
    ...getFeatureFlagsQuery({
      projectKey,
      query: {
        limit: MAX_OPTION_COUNT,
        summary: true,
        sort: '-creationDate',
        env: [environmentKey],
        filter: {
          query: debouncedSearchTerm,
        },
      },
    }),
    placeholderData: keepPreviousData,
  });

  const isPending = status === 'pending' || fetchStatus === 'fetching';

  interface Item {
    id: string;
    flag: FeatureFlag;
    isOn: boolean;
  }

  const items: Item[] =
    data?.items.map((flag) => ({
      id: flag.key,
      flag,
      isOn: isFlagOnForEnvironment(getFlagConfigurationForEnvironment(flag, environmentKey)),
    })) ?? [];

  return (
    <Dialog>
      {({ close }) => (
        <ComboBox
          aria-label="Select a flag"
          menuTrigger="focus"
          autoFocus
          allowsEmptyCollection
          shouldFocusWrap
          items={items}
          defaultFilter={undefined}
          inputValue={searchTerm}
          onInputChange={setSearchTerm}
          onSelectionChange={(key) => {
            if (typeof key === 'string') {
              const selectedItem = items.find((item) => item.id === key);
              if (selectedItem) {
                queryClient.setQueryData(
                  getFeatureFlagQuery({
                    projectKey,
                    featureFlagKey: selectedItem.flag.key,
                    query: {
                      env: [environmentKey],
                    },
                  }).queryKey,
                  selectedItem.flag,
                );

                onSelectionChange(selectedItem.flag);
              }
              close();
            }
          }}
        >
          <Group>
            {isPending ? <ProgressBar aria-label="loading" isIndeterminate /> : <Icon name="search" size="small" />}
            <Input placeholder="Search flags" />
            {searchTerm && <ComboBoxClearButton onPressEnd={() => setSearchTerm('')} excludeFromTabOrder />}
          </Group>
          <ListBox<Item> className={styles.ListBox}>
            {(item) => (
              <ListBoxItem id={item.flag.key} textValue={item.flag.name}>
                <div className={styles.Flag}>
                  {includeStatus && (
                    <FlagStatus
                      projectKey={projectKey}
                      featureFlagKey={item.flag.key}
                      environmentKey={environmentKey}
                    />
                  )}
                  <div className={styles.Name}>{item.flag.name}</div>
                  {includeTargeting && <TargetingTag isOn={item.isOn} />}
                </div>
              </ListBoxItem>
            )}
          </ListBox>
        </ComboBox>
      )}
    </Dialog>
  );
}
