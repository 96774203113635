import { Middleware } from 'redux';

// Automatically add timestamps to all actions
const timestampMiddleware: Middleware = () => (next) => (action) =>
  next({
    ...action,
    timestamp: Date.now(),
  });

/* eslint-disable import/no-default-export */
export default timestampMiddleware;
