import { getClauseCoercedValue } from './getClauseCoercedValue';

export function coerceClauseValues(
  values: unknown[],
  options: { expectedType?: 'string' | 'number'; preserveTypes?: boolean } = {},
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return values.map((v: any) => {
    const isCreated = getValueOptionProp(v, 'isCreated') ?? false;
    const value = getValueOptionProp(v, 'value') ?? v;
    const type = getValueOptionProp(v, 'type');

    // Preserve type of existing options or anything with an explicit type
    if (options.preserveTypes && (!isCreated || type)) {
      return value;
    }

    return getClauseCoercedValue(value, options.expectedType);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getValueOptionProp(valueObj: any, prop: string) {
  if (typeof valueObj === 'object' && 'get' in valueObj && typeof valueObj.get === 'function') {
    return valueObj.get(prop);
  }

  if (typeof valueObj === 'object' && prop in valueObj) {
    return valueObj[prop];
  }
}
