import { QueryFilter } from '@gonfalon/queryfilter';
import { PostContextsParams } from '@gonfalon/rest-api';

export enum ContextKind {
  USER = 'user',
  APPLICATION = 'ld_application',
  DEVICE = 'ld_device',
}

export type UIContextsView = Omit<PostContextsParams, 'filter'> & {
  filter?: QueryFilter;
};

export type UIContextsKindsView = {
  offset?: number;
  query?: string;
};
