import { Middleware } from 'redux';

import { isImmutableBeastModeError } from 'utils/httpUtils';

const beastModeMiddleware: Middleware = () => (next) => (action) => {
  const finalAction = action;
  if (isImmutableBeastModeError(action.error)) {
    finalAction.error = null;
    finalAction.keepState = true;
  }
  next(finalAction);
};

/* eslint-disable import/no-default-export */
export default beastModeMiddleware;
