import { getFeatureFlagStatusQuery } from '@gonfalon/rest-api';
import { ProgressBar } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import { useQuery } from '@tanstack/react-query';

import styles from './FlagPicker.module.css';

export function FlagStatus({
  projectKey,
  featureFlagKey,
  environmentKey,
}: {
  projectKey: string;
  featureFlagKey: string;
  environmentKey: string;
}) {
  const { status, data } = useQuery({
    ...getFeatureFlagStatusQuery({
      projectKey,
      featureFlagKey,
      environmentKey,
    }),
  });

  if (status === 'pending') {
    return <ProgressBar size="small" aria-label="loading" isIndeterminate className={styles.Status} />;
  }

  if (status === 'error') {
    return <Icon name="warning-outline" size="small" className={styles.Status} />;
  }

  const hasKnownLastEvent = typeof data?.lastRequested === 'string';
  const icon = hasKnownLastEvent ? 'status-active' : 'status-inactive';

  return <Icon aria-label="Flag status" name={icon} size="small" className={styles.Status} />;
}
