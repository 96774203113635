import { countTargetingRules } from './countTargetingRules';
import { FeatureFlagConfig } from './types';

export function countTargetingRulesTrackingEvents(config: FeatureFlagConfig) {
  if (config.trackEvents) {
    return countTargetingRules(config);
  }

  if (!config.trackEvents) {
    let count = 0;
    if (config.trackEventsFallthrough) {
      count++;
    }
    if (config.rules && config.rules.length > 0) {
      config.rules.forEach((rule) => {
        if (rule.trackEvents) {
          count++;
        }
      });
    }
    return count;
  }

  return 0;
}
