import { getClauseComponents } from './getClauseComponents';
import { Clause, Segment } from './types';

export function translateToText(
  clause: Clause,
  attribute: string, // could pull this out into a separate package for user-attributes
  mobileAttributesMapping: { [key: string]: string },
  segments: Segment[] = [],
) {
  const {
    contextKind,
    attribute: attr,
    operation,
    valuesString,
  } = getClauseComponents(clause, segments, attribute, mobileAttributesMapping);
  return `If ${contextKind ? `${contextKind} ` : ''}${attr} ${operation}${operation ? ' ' : ''}${valuesString}`;
}
