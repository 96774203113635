import { FeatureFlagConfig } from './types';

export function getFlagFallthroughRolloutVariationIndices(config: FeatureFlagConfig) {
  const fallthroughRolloutIndices: number[] = [];

  config.fallthrough?.rollout?.variations.forEach((wv) => {
    if (wv.variation !== undefined && wv.weight > 0) {
      fallthroughRolloutIndices.push(wv.variation);
    }
  });

  return fallthroughRolloutIndices;
}
