import { Member } from '@gonfalon/members';
import { checkAccess } from '@gonfalon/permissions';

import { FlagTemplateV2Collection } from './types';

export type Action =
  | 'updateProjectFlagDefaults'
  | 'updateDefaultClientSideAvailability'
  | 'updateIncludeInSnippetByDefault';

export function checkFlagTemplateAccess(member: Member, action: Action, templateCollection?: FlagTemplateV2Collection) {
  const access = templateCollection?._access;
  return checkAccess(member, access, action);
}
