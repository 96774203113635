import { getCountOfTargetsForVariation } from './getCountOfTargetsForVariation';
import { FeatureFlagConfig, Variation } from './types';

export function countContextTargets(
  variations: Variation[],
  config: Pick<FeatureFlagConfig, 'contextTargets' | 'targets'>,
) {
  let total = 0;
  for (const [index] of variations.entries()) {
    total += getCountOfTargetsForVariation(config, index);
  }
  return total;
}
