import { isBoolean, isNumber } from '@gonfalon/es6-utils';
import { isJSONValue } from '@gonfalon/types';

import { isVariationEmpty } from './isVariationEmpty';
import { Variation, VariationType } from './types';

export function getVariationType(variations: Variation[]): VariationType {
  const values = variations.filter((v) => !isVariationEmpty(v)).map((v) => v.value);

  if (values.length === 0) {
    return 'string';
  }

  if (values.every(isBoolean)) {
    return 'boolean';
  }

  if (values.every(isNumber)) {
    return 'number';
  }

  if (values.every(isJSONValue)) {
    return 'json';
  }

  return 'string';
}
